import React from 'react';
import Table from '../../../Table';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function Row({ card }) {
  const { t } = useTranslation();
  return (
    <>
      <Table.Row>
        <Table.Cell>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
            to={`/gift-cards/card-information?token=${card.token}`}
          >
            {card.token}
          </Link>
        </Table.Cell>
        <Table.Cell>{card.order_reference || '-'}</Table.Cell>
        <Table.Cell className={'capitalize'}>
          {t(card.card_status || '-')}
        </Table.Cell>
        <Table.Cell>{card.card_loaded_date || '-'}</Table.Cell>
        <Table.Cell>{card.expiration_date}</Table.Cell>
        <Table.Cell>
          <ShoppingCenterMoney value={card.breakage} />
        </Table.Cell>
      </Table.Row>
    </>
  );
}
