import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export function useCardTransactions() {
  const {
    cardTransactionMeta: meta,
    cardTransactionInfo: data,
    errorTransactionInfo: error,
  } = useSelector((state) => state.cardInformation);

  return {
    data: data || [],
    error,
    isError: error !== null,
    pagination: {
      isPaginated: meta ? meta.total > meta.per_page : false,
      totalPages: meta ? meta.total / meta.per_page : 0,
    },
  };
}

export function useTransactionTypeLabel(type) {
  const { t } = useTranslation();

  if (type === null) {
    return t('card.transaction.type.unknown');
  }

  return t(`card.transaction.type.${type}`, t('card.transaction.type.unknown'));
}

export function useTransactionWalletLabel(wallet) {
  const { t } = useTranslation();

  if (wallet === null || wallet === '') {
    return t('card.transaction.wallet.unknown');
  }

  return t(`card.transaction.wallet.${wallet.toLowerCase()}`, wallet);
}
