import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Tooltip from 'react-simple-tooltip';
import Table from '../../../../Table';
import { checkLoggedInUserPermissions } from '../../../../../utils/Helper';
import { GF_DARK_BLUE, GF_PERIWINKLE } from '../../../../../utils/Constants';
import ShoppingCenterMoney from '../../../../ShoppingCenterMoney';
import {
  useTransactionTypeLabel,
  useTransactionWalletLabel,
} from '../../../../../hooks/useCardInformation';

function linkToMerchant(merchantName) {
  if (checkLoggedInUserPermissions('new-shop-list')) {
    return `/shops/manage?keyword=${merchantName}`;
  }

  if (checkLoggedInUserPermissions('list-of-shops')) {
    return `/shops/shop-list?keyword=${merchantName}`;
  }

  return null;
}

export default function ListCardTransactions({ transactions, card }) {
  const { t } = useTranslation();

  return (
    <Table>
      <Table.Head>
        <tr>
          <Table.Header className="text-left">{t('Date')}</Table.Header>
          {card.is_mvc ? (
            <>
              <Table.Header className="text-left">
                {t('Description')}
              </Table.Header>
            </>
          ) : (
            <>
              <Table.Header className="text-left">TID</Table.Header>
              <Table.Header className="text-left">{t('Merchant')}</Table.Header>
              <Table.Header className="text-left">{t('Status')}</Table.Header>
            </>
          )}
          <Table.Header className="text-right">{t('Amount')}</Table.Header>
          <Table.Header className="text-right">
            {t('purchase-type')}
          </Table.Header>
        </tr>
      </Table.Head>
      <Table.Body>
        {transactions.map((transaction) => (
          <Row transaction={transaction} key={transaction.id} card={card} />
        ))}
      </Table.Body>
    </Table>
  );
}

function Row({ transaction, card }) {
  const { t } = useTranslation();

  const textColor = useMemo(() => {
    if (transaction.status === 'declined') {
      return 'text-gfCoral';
    }

    if (transaction.amount > 0) {
      return 'text-gfRobinBlue';
    }

    return 'text-gfDarkBlue';
  }, [transaction.status, transaction.amount]);

  const link = useMemo(
    () => linkToMerchant(transaction.merchant_name),
    [transaction.merchant_name],
  );

  const transactionTypeLabel = useTransactionTypeLabel(transaction.type);
  const walletLabel = useTransactionWalletLabel(transaction.wallet);

  return (
    <Table.Row className={textColor}>
      <Table.Cell className="text-left">{transaction.date}</Table.Cell>
      {card.is_mvc ? (
        <>
          <Table.Cell className="text-left">
            {transaction.description}
          </Table.Cell>
        </>
      ) : (
        <>
          <Table.Cell className="text-left">
            {transaction.terminal_id}
          </Table.Cell>
          <Table.Cell className="text-left">
            {link ? (
              <Link className="underline" to={link}>
                {transaction.merchant_name}
              </Link>
            ) : (
              transaction.merchant_name
            )}
          </Table.Cell>
          <Table.Cell className={'text-left font-MulishBold'}>
            {transaction.status === 'declined' && transaction.note !== '' ? (
              <div className="z-20 relative">
                <Tooltip
                  content={transaction.note}
                  customCss={`
                      white-space: nowrap;
                      z-index: 9999;
                  `}
                  zIndex={9999}
                  background="#FFF"
                  border={GF_PERIWINKLE}
                  color={GF_DARK_BLUE}
                  fontFamily="inherit"
                  radius={4}
                  padding={10}
                  placement="top"
                >
                  <div className="cursor-help uppercase">
                    {transaction.status}
                  </div>
                </Tooltip>
              </div>
            ) : (
              <span className="uppercase">{transaction.status}</span>
            )}
          </Table.Cell>
        </>
      )}
      <Table.Cell className={'font-MulishBold text-right'}>
        <ShoppingCenterMoney value={transaction.amount} />
      </Table.Cell>
      <Table.Cell className={'font-MulishBold text-right'}>
        <div>
          <span>{transactionTypeLabel}</span> <br />
          {transaction.wallet && (
            <span className="text-gray-500">{walletLabel}</span>
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
}
