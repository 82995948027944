import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { format, parse } from 'date-fns';
import Filter from '../Filter';
import useApiHook from '../../../../hooks/useAPI';
import reportAPI from '../../../../api/reportAPI';
import {
  exportComponentAsJPEG,
  exportComponentAsPNG,
} from 'react-component-export-image';
import { cn } from '../../../../utils';
import Summaries from '../AllSales/Summaries';
import SummaryModal from '../AllSales/SummaryModal';
import DailyCard from './DailyCard';
import Chart from './Chart';
import { jsPDF } from 'jspdf';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';
import WeeklyChart from './WeeklyChart';
import MessageAlert from '../../../Alert/MessageAlert';
import html2canvas from 'html2canvas';
import TabItemContainer from '../../../UIComponents/TabItemContainer';
import { COLORS } from '../../../../enums/common.enum';
import { useSettings } from '../../../../hooks/useSettings';
import { useSelector } from 'react-redux';

const chartTypes = Object.freeze({
  CARD_SOLD: 'cards-sold',
  AMOUNTS_LOADED: 'amounts-loaded',
  BOTH: 'both',
});

export default function AllSalesSummary() {
  const componentRef = useRef();
  const component2Ref = useRef();
  const { t } = useTranslation();
  const [isOrderDetailsModalOpened, setIsOrderDetailsModalOpened] =
    useState(false);
  const [orderDetails, setOrderDetails] = useState(false);
  const [filterData, setFilterData] = useState({});
  const { dateFormat } = useSettings();
  const { selectedTag } = useSelector((state) => state.tag);

  useEffect(() => {
    getSalesList({});
  }, []);
  const [allSalesSummaryHandler, getAllSalesSummary] = useApiHook(
    reportAPI.getAllSalesSummarySummary,
  );

  const fromDate = useMemo(
    () =>
      filterData.from_date
        ? parse(filterData.from_date, 'yyyy-MM-dd', new Date())
        : new Date(),
    [filterData.from_date],
  );

  const toDate = useMemo(
    () =>
      filterData.to_date
        ? parse(filterData.to_date, 'yyyy-MM-dd', new Date())
        : new Date(),
    [filterData.to_date],
  );

  const weeklyBreakdownChartTitle = useMemo(() => {
    return `${t(
      'weekly-breakdown',
    )} / ${t('Cards-Sold')} -> ${format(fromDate, dateFormat)} - ${format(toDate, dateFormat)}`;
  }, [t, fromDate, toDate, dateFormat]);

  const AllSalesSummaryChartTitle = useMemo(() => {
    return `${t(
      'weekly-breakdown',
    )} / ${t('Cards-Sold')} -> ${format(fromDate, dateFormat)} - ${format(toDate, dateFormat)}`;
  }, [t, fromDate, toDate, dateFormat]);

  // to store in a helper
  const savePDF = (componentRef, elementId = 'chart1', fileName = null) => {
    const divHeight = document.getElementById(elementId).clientHeight;
    const divWidth = document.getElementById(elementId).clientWidth;
    const ratio = divHeight / divWidth;

    html2canvas(componentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({ orientation: 'l' });
      const width = pdf.internal.pageSize.getWidth();
      const height = pdf.internal.pageSize.getHeight();
      const h = ratio * width;
      pdf.addImage(imgData, 'JPEG', 0, 0, width, h);

      const localFileName = fileName ?? elementId;

      pdf.save(localFileName + '.pdf');
    });
  };

  const [weeklySalesSummaryHandler, getWeeklySalesSummaryHandler] = useApiHook(
    reportAPI.getAllSalesWeeklyBreakdownSummary,
  );

  const [allSalesSummaryExport, getAllSalesSummaryExport] = useApiHook(
    reportAPI.getExcelReportAllSalesSummary,
  );

  const [
    allSalesSummaryWeeklyBreakdownExport,
    getAllSalesSummaryWeeklyBreakdownExport,
  ] = useApiHook(reportAPI.getExcelReportAllSalesSummaryWeeklyBreakdown);

  const [chartType, setChartType] = useState(chartTypes.CARD_SOLD);

  const CustomizedTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div className="flex flex-col p-4 border bg-white rounded border-gfDarkSand font-MulishBold text-sm space-y-2 shadow-md">
          <div className="py-1">
            {t(label || payload[0].payload.day_of_week)}
          </div>
          {(chartType === chartTypes.CARD_SOLD ||
            chartType === chartTypes.BOTH) && (
            <div className="">
              <span className="text-gfCoral">{` ${t('Cards-Sold')} : ${
                payload[0].payload.operations
              }`}</span>
            </div>
          )}

          {(chartType === chartTypes.AMOUNTS_LOADED ||
            chartType === chartTypes.BOTH) && (
            <div className="flex flex-row">
              <span className="text-gfLightBlue mr-1">{`${t('Amounts-loaded')} : `}</span>
              <div className="flex justify-start text-gfLightBlue">
                <ShoppingCenterMoney value={payload[0].payload.amount} />
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <div className="flex flex-row flex-wrap gap-5 justify-center items-center">
        {payload.map((entry, index) => (
          <div key={index} className="flex flex-row items-center gap-1">
            <div
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: entry.payload.fill,
              }}
            ></div>
            <div style={{ color: entry.payload.fill }}>
              {t(entry.payload.day_of_week)}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const getSalesList = useCallback(
    ({ fromDate = null, toDate = null, timeInterval = null, tag = null }) => {
      let params = {
        from_date: format(fromDate ?? new Date(), 'yyyy-MM-dd'),
        to_date: format(toDate ?? new Date(), 'yyyy-MM-dd'),
        format: timeInterval?.value,
        tagging_id: tag?.value,
      };

      params.tagging_name = tag?.label;

      setFilterData(params);

      getWeeklySalesSummaryHandler(params);
      getAllSalesSummary(params);
    },
    [],
  );

  const fulfilledWeek = useMemo(() => {
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    const response = [];
    days.forEach((day) => {
      const data = weeklySalesSummaryHandler?.data?.data.find(
        ({ day_of_week }) => day_of_week === day,
      );

      response.push(
        data ?? {
          day_of_week: t(day),
          operations: 0,
          amount: 0,
        },
      );
    });

    return response;
  });

  return (
    <TabItemContainer
      title={t('reports.sales.all-sales-summary.title')}
      description={t('reports.sales.all-sales-summary.description')}
    >
      <div className={'max-w-5xl'}>
        <Filter
          onFilter={(data) => getSalesList(data)}
          status={allSalesSummaryHandler}
          channel={false}
          timeInterval={true}
          tag={selectedTag.enabled_tagging}
          loading={
            allSalesSummaryHandler.loading || weeklySalesSummaryHandler.loading
          }
          applyValidationRuleDateRange={false}
          validationRulePeriod={3}
          maxDate={new Date()}
        />
      </div>

      {!allSalesSummaryHandler.loading &&
        !weeklySalesSummaryHandler.loading &&
        !allSalesSummaryHandler?.data?.data?.length && (
          <MessageAlert
            message={t('There-are-no-information-to-display-for-this-date')}
          />
        )}

      {!allSalesSummaryHandler.loading &&
        !weeklySalesSummaryHandler.loading &&
        !!allSalesSummaryHandler?.data?.data?.length && (
          <main>
            {!!allSalesSummaryHandler?.data?.summary && (
              <div className={'mb-10'}>
                <Summaries
                  cards={[
                    {
                      bottomLabel: t('Number-of-transactions'),
                      value: allSalesSummaryHandler?.data?.summary?.total_sales,
                    },
                    {
                      bottomLabel: t('Total-loaded'),
                      isCurrency: true,
                      value: allSalesSummaryHandler?.data?.summary?.total_value,
                    },
                    {
                      bottomLabel: t('Average-Loaf'),
                      isCurrency: true,
                      value: allSalesSummaryHandler?.data?.summary?.average,
                    },
                  ]}
                />
              </div>
            )}
            <header className={'flex justify-between mb-5'}>
              <div className={'flex h-10'}>
                <button
                  className={cn(
                    'rounded-bl-md rounded-tl-md px-5',
                    chartType === 'cards-sold'
                      ? 'bg-gfDarkBlue text-white'
                      : 'bg-gfPeriwinkle',
                  )}
                  onClick={() => setChartType(chartTypes.CARD_SOLD)}
                >
                  {t('Cards-Sold')}
                </button>
                <button
                  className={cn(
                    'px-5',
                    chartType === 'amounts-loaded'
                      ? 'bg-gfDarkBlue text-white'
                      : 'bg-gfPeriwinkle',
                  )}
                  onClick={() => setChartType(chartTypes.AMOUNTS_LOADED)}
                >
                  {t('Amounts-loaded')}
                </button>
                <button
                  className={cn(
                    'rounded-br-md rounded-tr-md px-5',
                    chartType === 'both'
                      ? 'bg-gfDarkBlue text-white'
                      : 'bg-gfPeriwinkle',
                  )}
                  onClick={() => setChartType(chartTypes.BOTH)}
                >
                  {t('Both')}
                </button>
              </div>
              <div className={'flex space-x-2 h-10'}>
                <button
                  className="bg-gfCoral hover:bg-opacity-75 rounded px-4 "
                  onClick={() =>
                    exportComponentAsPNG(componentRef, {
                      fileName: AllSalesSummaryChartTitle,
                    })
                  }
                >
                  {t('save-png')}
                </button>
                <button
                  className="bg-gfCoral hover:bg-opacity-75 rounded px-4 "
                  onClick={() =>
                    exportComponentAsJPEG(componentRef, {
                      fileName: AllSalesSummaryChartTitle,
                    })
                  }
                >
                  {t('save-jpeg')}
                </button>
                <button
                  className="bg-gfCoral hover:bg-opacity-75 rounded px-4 "
                  onClick={() =>
                    savePDF(componentRef, 'chart1', AllSalesSummaryChartTitle)
                  }
                >
                  {t('export-pdf')}
                </button>
                <button
                  id="exportExcelButton"
                  className="bg-gfCoral hover:bg-opacity-75 rounded px-4 "
                  onClick={() => getAllSalesSummaryExport(filterData)}
                >
                  {t('export-excel')}
                </button>
              </div>
            </header>
            {allSalesSummaryHandler?.data && (
              <div ref={componentRef}>
                <div
                  id="chart1"
                  className="flex justify-center w-full my-3 flex-col"
                >
                  <div className="flex flex-row justify-center items-center font-MulishBold py-4 text-2xl">
                    {weeklyBreakdownChartTitle}
                  </div>
                  <Chart
                    data={allSalesSummaryHandler?.data?.data}
                    chartType={chartType}
                    intervalFormat={filterData.format}
                  />
                </div>
              </div>
            )}

            {fulfilledWeek && (
              <div className={'flex flex-wrap xl:flex-nowrap mt-10'}>
                {fulfilledWeek.map((item) => (
                  <div className={'sm:w-1/3 md:w-1/4 lg:w-1/5 px-2 mb-4'}>
                    <DailyCard
                      item={item}
                      totalCardsSold={
                        weeklySalesSummaryHandler?.data?.summary.total_sales
                      }
                    />
                  </div>
                ))}
              </div>
            )}
            <section
              className={'flex flex-wrap justify-center space-x-8 my-20'}
            >
              {['cards-sold', 'both'].includes(chartType) && (
                <div
                  className={cn('w-96', chartType === 'both' ? 'mr-12' : '')}
                >
                  <div className="text-center mx-auto text-2xl font-MulishBold text-gfLightBlue">
                    {t('Cards-Sold')}
                  </div>
                  <div className="w-full h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart
                        width={500}
                        height={500}
                        margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
                      >
                        <Pie
                          data={weeklySalesSummaryHandler.data?.data ?? []}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          outerRadius={120}
                          fill="#8884d8"
                          dataKey="operations"
                        >
                          {weeklySalesSummaryHandler.data?.data &&
                            weeklySalesSummaryHandler.data?.data.map(
                              (entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              ),
                            )}
                        </Pie>
                        <Legend
                          width={400}
                          content={renderLegend}
                          dataKey="day_of_week"
                          wrapperStyle={{ bottom: '-5px' }}
                        />
                        <Tooltip content={CustomizedTooltip} />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              )}

              {['amounts-loaded', 'both'].includes(chartType) && (
                <div className={'w-96'}>
                  <div className="text-center mx-auto text-2xl font-MulishBold text-gfLightBlue">
                    {t('Amounts-loaded')}
                  </div>
                  <div className="w-full h-80">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart
                        width={400}
                        height={400}
                        margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
                      >
                        <Pie
                          data={weeklySalesSummaryHandler.data?.data ?? []}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          outerRadius={120}
                          fill="#8884d8"
                          dataKey="amount"
                        >
                          {weeklySalesSummaryHandler.data?.data &&
                            weeklySalesSummaryHandler.data?.data.map(
                              (entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              ),
                            )}
                        </Pie>
                        <Legend
                          width={400}
                          content={renderLegend}
                          wrapperStyle={{ bottom: '-5px' }}
                          dataKey="day_of_week"
                        />
                        <Tooltip content={CustomizedTooltip} />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              )}
            </section>
            <div className={'mt-10'}>
              <header className={'flex justify-end mb-5'}>
                <div className={'flex space-x-2 h-10'}>
                  <button
                    id="savePnfButton"
                    className="bg-gfCoral hover:bg-opacity-75 rounded px-4 "
                    onClick={() =>
                      exportComponentAsPNG(component2Ref, {
                        fileName: weeklyBreakdownChartTitle,
                      })
                    }
                  >
                    {t('save-png')}
                  </button>
                  <button
                    id="saveJpegButton"
                    className="bg-gfCoral hover:bg-opacity-75 rounded px-4 "
                    onClick={() =>
                      exportComponentAsJPEG(component2Ref, {
                        fileName: weeklyBreakdownChartTitle,
                      })
                    }
                  >
                    {t('save-jpeg')}
                  </button>
                  <button
                    id="exportPdfButton"
                    className="bg-gfCoral hover:bg-opacity-75 rounded px-4 "
                    onClick={() =>
                      savePDF(
                        component2Ref,
                        'chart2',
                        weeklyBreakdownChartTitle,
                      )
                    }
                  >
                    {t('export-pdf')}
                  </button>
                  <button
                    id="exportExcelButton"
                    className="bg-gfCoral hover:bg-opacity-75 rounded px-4 "
                    onClick={() =>
                      getAllSalesSummaryWeeklyBreakdownExport(filterData)
                    }
                  >
                    {t('export-excel')}
                  </button>
                </div>
              </header>
              <div ref={component2Ref}>
                <div id="chart2">
                  <div className="flex flex-row justify-center items-center font-MulishBold py-4 text-2xl">
                    {weeklyBreakdownChartTitle}
                  </div>
                  <WeeklyChart
                    data={fulfilledWeek}
                    chartType={chartType}
                    CustomizedTooltip={CustomizedTooltip}
                  />
                </div>
              </div>
            </div>
            <SummaryModal
              ticket={orderDetails}
              isOpen={isOrderDetailsModalOpened}
              onClose={() => setIsOrderDetailsModalOpened(false)}
            />
          </main>
        )}
    </TabItemContainer>
  );
}
