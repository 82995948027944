import {
  GF_CORAL,
  GF_DARK_BLUE,
  GF_GREY,
  GF_PERIWINKLE,
} from '../../../utils/Constants';

const DropDownStyle = (value) => ({
  control: (provided) => ({
    ...provided,
    boxShadow: 'none',
    borderColor: GF_PERIWINKLE,
    borderRadius: '4px',
    minHeight: '40px',
    paddingBottom: '2px',
    color: GF_DARK_BLUE,
    cursor: 'pointer',
    fontWeight: '600',

    '&:hover': {
      background: GF_PERIWINKLE,
      color: GF_DARK_BLUE,
    },

    '&:hover div': {
      color: GF_DARK_BLUE,
    },

    '&:focus': {
      borderColor: GF_PERIWINKLE,
      background: GF_PERIWINKLE,
    },
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '4px',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor:
      state.isSelected && state.data.id === value.id
        ? GF_DARK_BLUE
        : state.isFocused
          ? GF_CORAL
          : 'white',
    color:
      state.isSelected && state.data.id === value.id ? '#FFF' : GF_DARK_BLUE,
    '&:hover': {
      background: GF_CORAL,
      color: '#FFF',
    },
  }),

  menuList: (provided) => ({
    ...provided,
    color: GF_DARK_BLUE,
  }),

  singleValue: (provided) => ({
    ...provided,
    color: GF_DARK_BLUE,
  }),

  placeholder: (provided) => ({
    ...provided,
    color: GF_GREY,
  }),

  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: GF_CORAL,
      borderRadius: '20px',
      padding: '5px 6px',
      'min-width': '60px',
      ':hover': {
        backgroundColor: GF_CORAL,
      },
      focus: {
        backgroundColor: GF_CORAL,
      },
    };
  },
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: state.isSelected ? GF_CORAL : GF_DARK_BLUE,
    backgroundColor: GF_CORAL,
    'min-width': '60px',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? GF_DARK_BLUE : '#FFFFFF',
    color: GF_DARK_BLUE,
    'border-radius': '100%',
    'max-height': '22px',
    'margin-bottom': '2px',
    'margin-top': '2px',
    pointer: 'cursor',
    ':hover': {
      backgroundColor: GF_PERIWINKLE,
    },
    focus: {
      backgroundColor: GF_CORAL,
      color: GF_PERIWINKLE,
    },
  }),
});

export default DropDownStyle;
