import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Userpilot } from 'userpilot';
import Container from '../../../UIComponents/Container';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../../Form';
import CloseIcon from '../../../../assets/svg/CloseIcon';
import { Step, Steps } from '../../../Step';
import {
  ERROR_TICKET_INFO,
  getAdditionalProducts,
  INITIAL_LOAD_CARD,
  LOADING_TICKET_INFO,
  OPEN_TICKET_PRINT,
  RECEIVED_TICKET_INFO,
} from '../../../../actions/LoadCardActions';
import TokenForm from './TokenForm';
import { TAG_CHANGED } from '../../../../actions/TagActions';
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions';
import LoadCardTicketModal from '../../../Modal/LoadCardTicketModal';
import PaymentMethodField from '../../Orders/PaymentMethodField';
import DeskField from '../../Orders/DeskField';
import ChannelField from '../../Orders/ChannelField';
import { Card, CardFooter, CardTitle } from '../../../Card';
import MessageAlert from '../../../Alert/MessageAlert';
import AdditionalProductsField from './AdditionalProductsField';
import useAdditionalProducts from '../../../../hooks/useAdditionalProducts';
import useLoadCard from '../../../../hooks/useLoadCard';
import CustomerInformationField from '../../Orders/CustomerInformationField';
import {
  useCustomerFields,
  useReconciliationFields,
} from '../../../../hooks/useCustomerFields';
import { useTicketNumber } from '../../../../hooks/useTicketNumber';
import { Button } from '../../../Button';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';
import { Tags } from '../../../Tags';
import { useIsTaggingEnabled } from '../../../../hooks/useTags';

const OrderSummary = ({ lineItems, totalAmount }) => {
  const { tagCurrency } = useSelector((state) => state.tag);
  const { t } = useTranslation();

  return (
    <Card>
      <CardTitle>{t('place-b2b-order.order-details')}</CardTitle>
      {lineItems.map((lineItem, index) => (
        <div
          key={index}
          className="flex flex-row justify-between items-center"
          data-testid={
            lineItem.additionalProduct
              ? `additionalProduct_${lineItem.name}`
              : lineItem.description
                ? 'purchasefee'
                : `card_${index}`
          }
        >
          <div>
            <span data-testid="cardValue">{lineItem.name}</span>
            {lineItem.description && (
              <div className={'text-xs'}>({lineItem.description})</div>
            )}
          </div>
          <div className={'flex flex-row space-x-2'}>
            <ShoppingCenterMoney
              className={lineItem.onRemove ? '' : ' pr-6'}
              value={lineItem.amount}
            />
            {lineItem.onRemove && (
              <button onClick={lineItem.onRemove}>
                <CloseIcon w={16} h={17} />
              </button>
            )}
          </div>
        </div>
      ))}
      <CardFooter className="font-MulishBold pr-6" data-testid="totalLine">
        {t('Total')}: <ShoppingCenterMoney value={totalAmount} />
      </CardFooter>
    </Card>
  );
};

export default function LoadCard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fetch: fetchTicket } = useTicketNumber();
  const [checkAmounts, setCheckAmounts] = useState(false);
  const { products } = useAdditionalProducts();
  const { hasFields: hasCustomerFields, fields: customerFields } =
    useCustomerFields();
  const { hasFields: hasReconciliationFields, fields: reconciliationFields } =
    useReconciliationFields();
  const { tagCurrency, selectedTag } = useSelector((state) => state.tag);
  const { appCount } = useSelector((state) => state.commonReducer);
  const { deafultChannel, channels, desks, fields, loadingLoadCards } =
    useSelector((state) => state.loadCard);
  const [isOpenTicketModal, setIsOpenTicketModal] = useState(false);
  const [resetTokenFormFunction, setResetTokenFormFunction] = useState(
    () => {},
  );
  const [hasInvalidTokens, setHasInvalidTokens] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      cards: [],
      fields: {},
      paymentMethod: [{ method_id: '', amount: 0 }],
      additionalProducts: [],
      desk: '',
      channel: '',
      tags: [],
    },
  });

  const { setValue } = methods;

  useEffect(() => {
    Userpilot.reload();

    if (appCount === 0) {
      dispatch({ type: INITIAL_LOAD_CARD });
      dispatch(getAdditionalProducts());
    }

    window.addEventListener(TAG_CHANGED, () => {
      dispatch({ type: INITIAL_LOAD_CARD });
      dispatch(getAdditionalProducts());
    });

    window.addEventListener(TERMINAL_CHANGED, () => {
      dispatch({ type: INITIAL_LOAD_CARD });
      dispatch(getAdditionalProducts());
    });

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {});
      window.removeEventListener(TERMINAL_CHANGED, () => {});
      window.removeEventListener(OPEN_TICKET_PRINT, () => {});
    };
  }, []);

  useEffect(() => {
    if (deafultChannel) {
      setValue('channel', deafultChannel, { shouldValidate: true });
    }
  }, [deafultChannel, setValue]);

  const defaultDesk = useMemo(() => {
    if (desks === null || desks?.length === 0) {
      return null;
    }

    return desks[0];
  }, [desks]);

  useEffect(() => {
    if (defaultDesk) {
      setValue('desk', defaultDesk, { shouldValidate: true });
    }
  }, [defaultDesk, setValue]);

  const cards = useFieldArray({ name: 'cards', control: methods.control });
  const paymentMethod = useFieldArray({
    name: 'paymentMethod',
    control: methods.control,
  });
  const { isValid } = methods.formState;
  const onRemoveCard = useCallback((index) => cards.remove(index), [cards]);

  const { lineItems, totalAmount, isTotalAmountCovered, placeOrder } =
    useLoadCard({
      cards: methods.watch('cards'),
      additionalProducts: methods.watch('additionalProducts'),
      paymentMethods: methods.watch('paymentMethod'),
      onRemoveCard,
    });

  const addedTokens = useMemo(
    () => cards.fields.map((card) => card.token),
    [cards],
  );

  async function onSubmit(data) {
    setCheckAmounts(false);

    if (isTotalAmountCovered === false) {
      setCheckAmounts(true);

      return;
    }

    if (data.paymentMethod.length === 1) {
      data.paymentMethod[0].amount = totalAmount;
    }

    const payments = data.paymentMethod.map(({ amount, method_id }) => ({
      amount: parseFloat(amount).toFixed(2),
      method_id,
    }));

    const tokens = data.cards.map(({ amount, token }) => ({
      amount,
      token,
      valid: true, // requested by the backend
    }));

    const fields = Object.keys(data.fields !== undefined ? data.fields : [])
      .map((id) => ({
        field_id: parseInt(id),
        value: data.fields[id],
      }))
      .filter((field) => field.value !== '');

    const additionalProducts =
      data.additionalProducts?.map((item) => ({
        product_id: parseInt(item.product),
        quantity: parseInt(item.quantity),
      })) || [];

    let payload = {
      payments,
      tokens,
      fields,
      additional_products: additionalProducts,
      channel: data.channel?.id || 0,
      desk: data.desk?.id || 0,
      additional_comment: data.additional_comment || null,
    };

    if (selectedTag.purchase_fee_enabled) {
      payload = {
        ...payload,
        purchase_fee: selectedTag.purchase_fee?.amount_with_vat ?? null,
      };
    }

    if (selectedTag.enabled_tagging && data.tags?.length > 0) {
      payload = {
        ...payload,
        tagging_ids: data.tags.map((tag) => {
          return { id: tag.value };
        }),
      };
    }

    setIsOpenTicketModal(true);
    const ticketNumber = await placeOrder(payload);

    if (!ticketNumber) {
      return;
    }

    try {
      dispatch({ type: LOADING_TICKET_INFO });
      const ticket = await fetchTicket(ticketNumber);
      dispatch({ type: RECEIVED_TICKET_INFO, payload: ticket });
    } catch (error) {
      dispatch({ type: ERROR_TICKET_INFO, payload: error });
    }
  }

  function onAddCard(tokens, invalidTokens) {
    for (const token of tokens) {
      cards.append(token);
    }

    if (invalidTokens.length > 0) {
      setHasInvalidTokens(true);
    }
  }

  function onRequestingTokenRange() {
    setHasInvalidTokens(false);
  }

  function onReset() {
    setHasInvalidTokens(false);
    resetTokenFormFunction();
    methods.reset();
  }

  const shouldSelectDesk = useMemo(() => desks?.length > 1, [desks]);
  const shouldSelectChannel = useMemo(() => channels?.length > 1, [channels]);
  const hasAdditionalProducts = useMemo(() => products?.length > 0, [products]);

  const selectedPaymentMethods = methods.watch('paymentMethod');
  const isTaggingEnabled = useIsTaggingEnabled({
    selectedPaymentMethods,
  })?.isEnabled;

  return (
    <Container title={t('card-sales.title')}>
      <Form {...methods}>
        <Steps className="grid grid-cols-4 gap-x-4">
          <div className="col-span-full sm:col-span-3 xl:col-span-3 flex flex-col">
            {hasInvalidTokens && (
              <div className="mb-4">
                <MessageAlert
                  message={t('add-tokens.invalid-tokens.description')}
                />
              </div>
            )}
            <Step
              title={t(
                'Enter Gift Card TOKEN & amount or swipe the card in the magstripe reader',
              )}
              required
            >
              <TokenForm
                onSetReset={(callback) => {
                  setResetTokenFormFunction(() => callback);
                }}
                onRequestingTokenRange={onRequestingTokenRange}
                onAdd={onAddCard}
                tokens={addedTokens}
              />
              {cards.fields.map((card, index) => (
                <div key={card.id} hidden>
                  <Controller
                    name={`cards.${index}.token`}
                    defaultValue={card.token}
                    render={(field) => <input type="hidden" {...field} />}
                  />
                  <Controller
                    name={`cards.${index}.amount`}
                    defaultValue={card.amount}
                    render={(field) => <input type="hidden" {...field} />}
                  />
                </div>
              ))}
            </Step>
          </div>
          <div className="col-span-full sm:col-span-3 xl:col-span-2 flex flex-col">
            <div className="flex flex-col">
              {selectedTag.purchase_fee_enabled && (
                <Step title={t('place-b2b-order.purchase-fee.title')}>
                  <div>
                    <div className="justify-start p-3 rounded-md inline-flex bg-gray-200">
                      <ShoppingCenterMoney
                        value={selectedTag.purchase_fee?.amount_with_vat}
                      />
                    </div>
                  </div>
                </Step>
              )}
              {hasAdditionalProducts && (
                <Step title={t('select-additional-product')}>
                  <AdditionalProductsField />
                </Step>
              )}
              {hasCustomerFields && (
                <Step title={t('Client-Information')}>
                  <CustomerInformationField
                    fields={customerFields}
                    methods={methods}
                  />
                </Step>
              )}
              <Step title={t('place-b2b-order.select-payment-method')} required>
                <PaymentMethodField paymentMethod={paymentMethod} />
              </Step>
              {shouldSelectChannel && (
                <Step title={t('select-a-channel')} required>
                  <ChannelField />
                </Step>
              )}
              {shouldSelectDesk && (
                <Step title={t('select-a-desk')} required>
                  <DeskField />
                </Step>
              )}
              <Step
                isLast={!isTaggingEnabled}
                title={t('place-b2b-order.information')}
              >
                <div className="grid grid-cols-2">
                  {hasReconciliationFields && (
                    <div className="flex flex-col space-y-1 mr-4">
                      <CustomerInformationField
                        columns={1}
                        fields={reconciliationFields}
                      />
                    </div>
                  )}
                  <div className="flex flex-col space-y-1">
                    <FormField
                      name="additional_comment"
                      defaultValue={''}
                      render={(field) => (
                        <FormItem className="h-full">
                          <FormControl>
                            <textarea
                              data-testid="additionalComment"
                              placeholder={t('place-b2b-order.add-a-comment')}
                              {...field}
                              className="rounded-md border border-gfPeriwinkle p-2 focus:outline-none focus:border-gfPeriwinkle h-full"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
              </Step>
              {isTaggingEnabled && (
                <Step
                  isLast={true}
                  title={t('place-b2b-order.tags-assignment')}
                >
                  <div className="grid grid-cols-2">
                    <div className="flex flex-col space-y-1">
                      <FormField
                        name="tags"
                        defaultValue={[]}
                        render={(field) => (
                          <FormItem>
                            <FormControl>
                              <Tags
                                inputRef={field.ref}
                                value={field.value}
                                {...field}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>
                  </div>
                </Step>
              )}
            </div>
            <div>
              {checkAmounts && (
                <div className="border rounded-md text-gfCoral border-gfCoral bg-gfCoral bg-opacity-25 p-4">
                  {t(
                    'The-input-amount-is-does-not-correspond-to-the-total-of-the-purchase',
                    {
                      amount: totalAmount,
                      currency: tagCurrency,
                    },
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-between row-start-1 row-end-4 col-start-4">
            <div>
              {cards.fields.length !== 0 && (
                <OrderSummary lineItems={lineItems} totalAmount={totalAmount} />
              )}
            </div>

            <div className="flex flex-row justify-end gap-5">
              <Button
                onClick={methods.handleSubmit(onSubmit)}
                data-testid="formSubmitButton"
                type="submit"
                variant="primary"
                disabled={!isValid || cards.fields.length === 0}
              >
                {t('card-sales.actions.submit')}
              </Button>
              <Button
                data-testid="formResetButton"
                onClick={onReset}
                type="button"
                disabled={loadingLoadCards}
                variant="secondary"
              >
                {t('Reset')}
              </Button>
            </div>
          </div>
        </Steps>
      </Form>
      <LoadCardTicketModal
        isOpen={isOpenTicketModal}
        onClose={() => setIsOpenTicketModal(false)}
      />
    </Container>
  );
}
