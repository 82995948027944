import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from '../Input/SelectField';
import { useTags } from '../../hooks/useTags';
import { cn } from '../../utils';

const Tags = forwardRef(function ({ ...props }, ref) {
  const { t } = useTranslation();
  const { data: tagList, isLoading } = useTags({ perPage: 100 });

  const options = useMemo(
    () =>
      tagList.map((tag) => ({
        value: tag.id,
        label: tag.name,
      })),
    [tagList],
  );

  return (
    <SelectField
      defaultValue={[]}
      isMulti
      name="name"
      options={options}
      menuPlacement="auto"
      maxMenuHeight={200}
      isLoading={isLoading}
      isClearable={false}
      classNamePrefix="select"
      placeholder={t('tags.assign.select-tags')}
      {...props}
    />
  );
});

const DisplayTag = forwardRef(({ className, children, ...props }, ref) => {
  return (
    <span
      ref={ref}
      className={cn(
        'text-sm font-medium me-2 px-2.5 py-1 rounded-full',
        className,
      )}
    >
      {children}
    </span>
  );
});

const TaggingListDropdown = forwardRef(function ({ onChangeHandler }, ref) {
  const { data: tagList, isLoading } = useTags({
    perPage: 100,
    inUse: true,
  });

  const options = useMemo(
    () =>
      tagList.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [tagList],
  );
  return (
    <SelectField
      isClearable={true}
      options={options}
      onChange={onChangeHandler}
    />
  );
});

export { Tags, DisplayTag, TaggingListDropdown };
