import React from 'react';
import { cn } from '../../utils';

function DataList({ children, className, ...props }) {
  return (
    <dl className={cn('space-y-1', className)} {...props}>
      {children}
    </dl>
  );
}

function DataListItem({ children, label, horizontal, className, ...props }) {
  return (
    <div
      className={cn(
        {
          'grid grid-cols-2 gap-4 items-center': horizontal,
          'flex flex-col space-y-2': !horizontal,
        },
        className,
      )}
      {...props}
    >
      <dt role="term" className={cn('font-MulishBold')}>
        {label}:
      </dt>
      <dd role="definition">{children}</dd>
    </div>
  );
}

export { DataList, DataListItem };
