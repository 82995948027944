import PhoneInput from '@simpleview/react-phone-input-2';
import React, { useMemo, forwardRef, useCallback, useState } from 'react';
import { PHONE_COUNTRIES } from '../../utils/PhoneCountriesRegex';
import { lowerCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { cn } from '../../utils';
import { useSelector } from 'react-redux';

const countryCodeInputStyle = {
  border: 'none',
  color: '#2d3748',
  padding: '0.5rem 0.5rem 0.5rem 3rem',
  width: '100%',
  fontSize: '16px',
  fontFamily: 'MulishRegular',
};
const countryCodeButtonStyle = {
  border: 'none',
  color: '#2d3748',
  margin: 0,
};

const onKeyUpHandlerPhone = (event) => {
  event.preventDefault();
  if (phoneNumberIsValid !== true) {
    return false;
  }
  return true;
};

const PhoneField = forwardRef(function (
  {
    value,
    name,
    onChange,
    country,
    required,
    error = null,
    className,
    onValidate,
    ...props
  },
  ref,
) {
  const { t } = useTranslation();
  const { selectedTag } = useSelector((state) => state.tag);

  const onlyCountries = useMemo(() => {
    return PHONE_COUNTRIES.map((country) => {
      return lowerCase(country.iso2);
    });
  }, [PHONE_COUNTRIES]);

  const localization = useMemo(() => {
    const mapped = {};
    onlyCountries.forEach((country) => {
      if (country !== '') {
        mapped[country] = t(`card_information.countries.${country}`);
      }
    });
    return mapped;
  }, [t, onlyCountries]);

  const [isValid, setIsValid] = useState(true);

  const phoneNumberIsValid = useCallback(
    (localValue, localCountry) => {
      const correspondingCountry = PHONE_COUNTRIES.find(
        (countryCode) =>
          countryCode.iso2 ===
          (localCountry.iso2?.toUpperCase() ??
            localCountry.countryCode?.toUpperCase()),
      );
      if (correspondingCountry && localValue) {
        if (
          !new RegExp(correspondingCountry.validation).test(
            `+${localValue}`?.replace(/ /g, ''),
          )
        ) {
          return 'card_information.search.results.enter_valid_phone_number';
        }
        return true;
      }
      return 'card_information.search.results.enter_valid_phone_number';
    },
    [selectedTag, PHONE_COUNTRIES, localization, onlyCountries],
  );

  return (
    <PhoneInput
      {...props}
      containerClass={cn(
        `border
                        text-center
                        focus:outline-none
                        text-gfDarkBlue
                        focus:border-gfPeriwinkle
                        border-gfPeriwinkle
                        rounded-md
                        placeholder-gfGrey
                        w-full`,
        { 'border-red-400': !!error },
        className,
      )}
      inputClass="no-border"
      inputProps={{
        id: name,
        name,
        required,
        autoComplete: 'none',
        'data-testid': 'input-id',
        className:
          'focus:outline-none border  text-gfDarkBlue border-gfPeriwinkle rounded-md placeholder-gfGrey w-full',
      }}
      country={country}
      onlyCountries={onlyCountries}
      localization={localization}
      inputStyle={countryCodeInputStyle}
      buttonStyle={countryCodeButtonStyle}
      value={value}
      ref={ref}
      enableSearch={true}
      onEnterKeyPress={onKeyUpHandlerPhone}
      onChange={(phoneNumber, country) => {
        const valid = phoneNumberIsValid(phoneNumber, country);
        if (value.slice(country.dialCode.length).length > 0) {
          if (valid !== isValid) {
            setIsValid(valid);
            if (typeof onValidate === 'function') {
              onValidate(valid);
            }
          }
        }
        if (typeof onValidate === 'function') {
          onValidate(valid);
        }
        onChange(`+${phoneNumber}`);
      }}
    />
  );
});

export default PhoneField;
