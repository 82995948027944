import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ListCardTransactions from './ListCardTransactions';
import { getCardTransactionInformation } from '../../../../../actions/CardInformationActions';
import MessageAlert from '../../../../Alert/MessageAlert';
import FailAlert from '../../../../Alert/FailAlert';
import { Pagination } from '../../../../Pagination';
import { useCardTransactions } from '../../../../../hooks/useCardInformation';
import { Button } from '../../../../Button';

export default function CardTransactions({ printPreview, cardInformation }) {
  const { t } = useTranslation();
  const { pagination, data, isError, error } = useCardTransactions();
  const dispatch = useDispatch();

  function onPaginate({ selected }) {
    dispatch(
      getCardTransactionInformation(
        cardInformation.token,
        cardInformation.is_mvc,
        selected + 1,
      ),
    );
  }

  if (isError) {
    return <FailAlert message={error.message} />;
  }

  if (data.length === 0 && error !== null) {
    return (
      <MessageAlert message={t('There-are-no-transactions-to-be-displayed')} />
    );
  }

  return (
    <div className="space-y-3">
      {data && data.length > 0 && (
        <div>
          <div className="w-full flex flex-row justify-end items-center space-x-8 mb-4">
            {pagination.isPaginated && (
              <Pagination
                pageCount={pagination.totalPages}
                onPageChange={onPaginate}
              />
            )}
            <Button
              id="printButton"
              onClick={() => printPreview()}
              variant="primary"
            >
              {t('Print')}
            </Button>
          </div>

          <ListCardTransactions transactions={data} card={cardInformation} />
        </div>
      )}
    </div>
  );
}
