import { upperCase } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Userpilot } from 'userpilot';
import { useForm, useWatch } from 'react-hook-form';
import { Button } from '../../../Button';
import {
  CHANGE_PHONE_NUMBER,
  CHANGE_SEARCH_TYPE,
  getCardInformation,
  getCardsListByPhoneNumber,
  RESET_CARD_INFO,
  RESET_SEARCH_CARD_LIST,
  RESET_SEARCH_TYPE,
  SET_FOCUS_SELECTED,
} from '../../../../actions/CardInformationActions';
import InputWithClearButton from '../../../UIComponents/InputWithClearButton';
import SearchCardTypeDropdown from '../../../Dropdown/Components/SearchCardTypeDropdown';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';
import { RESET_APP_COUNT } from '../../../../actions/CommonActions';
import FailAlert from '../../../Alert/FailAlert';
import useAppConfig from '../../../../hooks/useAppConfig';
import PhoneField from '../../../Input/PhoneField';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../../Form';

const defaultValues = {
  cardPhoneNumber: '',
};

export default function SearchCard({
  setSelectedCard,
  requiredValidation,
  setRequiredValidation,
  setBackToList,
  searchLocation,
  history,
  setPhoneNumber,
  phoneNumber,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selectedTag } = useSelector((state) => state.tag);
  const { isFeatureEnabled } = useAppConfig();
  const [cardNumber, setCardNumber] = useState('');
  const { appCount } = useSelector((state) => state.commonReducer);
  const {
    selectedSearchType,
    errorCardInformation,
    loadListSearchCardByPhone,
    loadingCardInformation,
  } = useSelector((state) => state.cardInformation);
  const inputRef = useRef(null);
  const searchCardTypesList = [
    {
      id: 0,
      name: t('Token'),
      value: 'token',
    },
  ];

  if (
    isFeatureEnabled('searchCardByPhone') &&
    checkLoggedInUserPermissions('search-card-by-phone')
  ) {
    searchCardTypesList.push({
      id: 1,
      name: t('Phone'),
      value: 'phone',
    });
  }

  useEffect(() => {
    Userpilot.reload();
    if (appCount === 0) {
      dispatch({ type: RESET_CARD_INFO });
      dispatch({ type: RESET_SEARCH_CARD_LIST });
      dispatch({ type: RESET_SEARCH_TYPE });
    }

    window.addEventListener(SET_FOCUS_SELECTED, () => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.setSelectionRange(0, 9);
      }
    });

    const params = new URLSearchParams(searchLocation);
    setBackToList(params.get('isBackToList'));
    setPhoneNumber(params.get('phoneNumber'));

    if (phoneNumber && selectedSearchType.id === 1) {
      setSelectedCard(true);
      dispatch(getCardsListByPhoneNumber(phoneNumber));
    }

    const token = params.get('token');
    if (token && selectedSearchType.id === 0) {
      setSelectedCard(true);
      setCardNumber(token);
      dispatch(getCardInformation(token));
    }
  }, []);

  const onFocusHandler = (e) => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, 9);
    }
  };

  const onChangeHandlerSearchType = (searchType) => {
    dispatch({ type: RESET_CARD_INFO });
    dispatch({ type: CHANGE_SEARCH_TYPE, payload: searchType });
    dispatch({ type: CHANGE_PHONE_NUMBER, payload: '' });
  };

  const loadCardHandler = (data) => {
    if (selectedSearchType.id === 0 && cardNumber.length !== 0) {
      dispatch({ type: RESET_APP_COUNT });
      dispatch(getCardInformation(cardNumber));
      window.history.pushState(
        {},
        '',
        `/gift-cards/card-information?token=${cardNumber}`,
      );
      setRequiredValidation(false);
      setSelectedCard(true);
    } else if (
      selectedSearchType.id === 1 &&
      data.cardPhoneNumber !== undefined
    ) {
      window.history.pushState(
        {},
        '',
        `/gift-cards/card-information?phoneNumber=${data.cardPhoneNumber}`,
      );
      dispatch(getCardsListByPhoneNumber(data.cardPhoneNumber));
      dispatch({ type: CHANGE_SEARCH_TYPE, payload: searchCardTypesList[1] });
      setPhoneNumber(data.cardPhoneNumber);
      setSelectedCard(false);
      setRequiredValidation(false);
    } else {
      setRequiredValidation(true);
    }
  };

  const onKeyDownHandler = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      loadCardHandler();
    }
  };

  const onChangeCardNumber = (e) => {
    setRequiredValidation(false);
    setCardNumber(e.target.value);
    dispatch({ type: RESET_CARD_INFO });
  };

  const onClearCardNumber = () => {
    setCardNumber('');
    dispatch({ type: RESET_CARD_INFO });
  };

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });
  const { isValid, isSubmitting } = methods.formState;

  return (
    <Form {...methods}>
      <form onSubmit={methods.handleSubmit(loadCardHandler)}>
        <div className="grid grid-cols-4 md:grid-cols-5 gap-1 md:max-w-xl mx-auto auto-rows-max">
          <div className="col-span-1">
            {isFeatureEnabled('searchCardByPhone') &&
              checkLoggedInUserPermissions('search-card-by-phone') && (
                <SearchCardTypeDropdown
                  value={selectedSearchType?.id}
                  onChangeHandler={onChangeHandlerSearchType}
                  data-testid={'searchCardTypeListDropdownID'}
                  id={'searchCardTypeListDropdownID'}
                  searchCardTypesList={searchCardTypesList}
                />
              )}
          </div>
          <div className="col-span-3 mr-4 h-100">
            {selectedSearchType?.id === 0 && (
              <InputWithClearButton
                value={cardNumber}
                onChange={onChangeCardNumber}
                onClear={onClearCardNumber}
                placeholder={upperCase(t(selectedSearchType.value))}
                inputRef={inputRef}
                onFocus={onFocusHandler}
                onKeyDown={onKeyDownHandler}
              />
            )}
            {selectedSearchType?.id === 1 && (
              <FormField
                name="cardPhoneNumber"
                rules={{
                  validate: () =>
                    methods.formState?.errors?.phone?.message ?? true,
                  required: t('place-b2b-order.required'),
                }}
                defaultValue={''}
                render={(field) => {
                  return (
                    <FormItem>
                      <FormControl>
                        <PhoneField
                          {...field}
                          value={phoneNumber ?? field.value}
                          className="border
                         text-center
                         focus:outline-none
                         text-gfDarkBlue
                         text-xl
                         border-gfPeriwinkle
                         rounded
                         placeholder-gfGrey
                         w-full"
                          onValidate={(valid) => {
                            if (valid !== true) {
                              methods.setError('phone', {
                                type: 'pattern',
                                message: t(valid),
                              });
                              dispatch({ type: RESET_SEARCH_CARD_LIST });
                            } else {
                              methods.clearErrors('phone');
                            }
                          }}
                          country={String(
                            selectedTag.country_code,
                          ).toLocaleLowerCase()}
                        ></PhoneField>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            )}
          </div>
          <div>
            <Button
              id={'cardInfoButton'}
              loading={loadListSearchCardByPhone || loadingCardInformation}
              disabled={isSubmitting || !isValid}
              className="py-3"
              onClick={loadCardHandler}
              variant="primary"
            >
              {t('Search')}
            </Button>
          </div>
        </div>
        <div className={'mt-10'}>
          {errorCardInformation && selectedSearchType.id === 0 && (
            <FailAlert message={errorCardInformation.message} />
          )}
          {selectedSearchType.id === 0 && requiredValidation && (
            <FailAlert message={t('Please enter card number')} />
          )}
        </div>
      </form>
    </Form>
  );
}
