import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormField, FormItem, FormMessage } from '../../Form';
import TextField from '../../Input/TextField';
import SelectField from '../../Input/SelectField';
import { cn } from '../../../utils';
import { useSelector } from 'react-redux';
import PhoneField from '../../Input/PhoneField';

function Field({ field, methods }) {
  const { t, i18n } = useTranslation();
  const { type, label } = field;
  const { selectedTag } = useSelector((state) => state.tag);

  const isRequired = useMemo(() => field.amount_for_required === 0, [field]);
  const translatedLabel = useMemo(() => {
    return i18n.exists(`customer_fields.${label}`)
      ? t(`customer_fields.${label}`)
      : t(label);
  }, [i18n, t, label]);

  const rules = useMemo(() => {
    const schema = {};

    if (isRequired) {
      schema.required = t('validation.required', {
        attribute: translatedLabel,
      });
    }

    if (label === 'reconciliation_last_4_digits') {
      schema.pattern = {
        value: /^\d{4}$/,
        message: t('validation.digits', {
          digits: 4,
          attribute: translatedLabel,
        }),
      };
    }

    if (label === 'reconciliation_authentication_code') {
      schema.pattern = {
        value: /^[a-zA-Z0-9]+$/,
        message: t('validation.alpha_num', {
          attribute: translatedLabel,
        }),
      };
    }

    if (type === 'tel') {
      schema.validate = () => methods.formState?.errors?.phone?.message ?? true;
    }

    if (type === 'email') {
      schema.pattern = {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: t('Invalid-email-address'),
      };
    }

    return schema;
  }, [t, isRequired, type, translatedLabel, methods]);

  const labelParsed = useMemo(() => {
    return `${translatedLabel} ${isRequired ? '*' : ''}`;
  }, [isRequired, translatedLabel]);

  return (
    <FormField
      name={`fields.${field.id}`}
      rules={rules}
      defaultValue={''}
      render={(formField) => {
        if (type === 'select') {
          const options = field.options.map((option) => ({
            label: option.label,
            value: option.id,
          }));

          const selected = options.find(
            (option) => option.value === formField.value,
          );

          return (
            <FormItem data-testid={`fields.${field.id}`}>
              <FormControl>
                <SelectField
                  ref={formField.ref}
                  onChange={(value) => formField.onChange(value.value)}
                  isSearchable
                  options={options}
                  value={selected ? { label: selected.label } : null}
                  formatOptionLabel={(option) => (
                    <span data-testid={`scheme-` + option.label}>
                      {option.label}
                    </span>
                  )}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }

        if (type === 'tel') {
          return (
            <FormItem>
              <FormControl>
                <PhoneField
                  {...formField}
                  value={formField.value}
                  onValidate={(valid) => {
                    if (valid !== true) {
                      methods.setError('phone', {
                        type: 'pattern',
                        message: t(valid),
                      });
                    } else {
                      methods.clearErrors('phone');
                    }
                  }}
                  country={String(selectedTag.country_code).toLocaleLowerCase()}
                ></PhoneField>
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }

        return (
          <FormItem>
            <FormControl>
              <TextField type="text" {...formField} placeholder={labelParsed} />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}

function CustomerInformationField({ fields, methods, columns = 2 }) {
  if (!Array.isArray(fields) || fields.length === 0) {
    return null;
  }
  return (
    <div
      className={cn('grid gap-4', {
        'grid-cols-1': columns === 1,
        'grid-cols-2': columns === 2,
      })}
    >
      {fields.map((field) => (
        <Field field={field} methods={methods} key={field.id} />
      ))}
    </div>
  );
}

export default CustomerInformationField;
