const { appConfig = {} } = window;

function useAppConfig() {
  function isFeatureEnabled(name) {
    return (
      appConfig.flags &&
      (appConfig.flags === '*' || !!appConfig.flags.includes(name))
    );
  }

  function getConfigValue(name) {
    return appConfig[name];
  }

  return { isFeatureEnabled, getConfigValue };
}

export default useAppConfig;
