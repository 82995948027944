import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import TabItemContainer from '../../../UIComponents/TabItemContainer';
import Filter from '../Filter';
import Table from '../../../Table';
import ReactPaginate from 'react-paginate';
import useApiHook from '../../../../hooks/useAPI';
import { reportAPI } from '../../../../api';
import Row from './Row';
import { format } from 'date-fns';
import AlertHandler from '../../../Alert/AlertHandler';
import { useSelector } from 'react-redux';
import Summaries from '../AllSales/Summaries';
import { randomString } from '../../../../utils/Helper';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Fees() {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({});
  const pageRef = useRef(null);
  const { selectedTag } = useSelector((state) => state.tag);
  const [hideResult, setHideResult] = useState(false);

  const [feesReportHandler, getFeesReport] = useApiHook(
    reportAPI.getFeesReport,
  );

  const [feesReportOverviewHandler, getFeesReportOverview] = useApiHook(
    reportAPI.getFeesReportOverview,
  );

  const [feesReportExportHandler, feesReportExcelExport] = useApiHook(
    reportAPI.getExcelExportFeesReport,
  );

  const getFeesReportList = useCallback(
    ({ fromDate = null, toDate = null, feesType = null, page = 1 }) => {
      const params = {
        from_date: format(fromDate ?? new Date(), 'yyyy-MM-dd'),
        to_date: format(toDate ?? new Date(), 'yyyy-MM-dd'),
        fees_type: feesType.value,
        page: page,
        per_page: window?.appConfig?.REACT_APP_PAGINATION_PER_PAGE_ROWS,
      };
      getFeesReport(params);
      getFeesReportOverview(params);
    },
    [],
  );

  const handlePageClick = (page) => {
    const params = {
      from_date: format(filter.fromDate ?? new Date(), 'yyyy-MM-dd'),
      to_date: format(filter.toDate ?? new Date(), 'yyyy-MM-dd'),
      fees_type: filter.feesType.value,
      page: page.selected + 1,
      per_page: window?.appConfig?.REACT_APP_PAGINATION_PER_PAGE_ROWS,
    };
    getFeesReport(params);
  };

  const getExcelReport = () => {
    const params = {
      from_date: format(filter.fromDate ?? new Date(), 'yyyy-MM-dd'),
      to_date: format(filter.toDate ?? new Date(), 'yyyy-MM-dd'),
      fees_type: filter.feesType.value,
    };
    feesReportExcelExport(params);
  };

  const resetResult = () => {
    setHideResult(true);
  };

  return (
    <TabItemContainer
      title={t('reports.finance.fees.title')}
      description={t('reports.finance.fees.description')}
    >
      <Filter
        onFilter={(data) => {
          setFilter(data);
          getFeesReportList(data);
          setHideResult(false);
        }}
        feesType={true}
        status={feesReportHandler}
        onChange={resetResult}
        maxDate={new Date()}
      />

      {!!feesReportOverviewHandler?.data &&
        !!feesReportHandler.data?.data?.length &&
        !hideResult && (
          <Summaries
            summary={feesReportOverviewHandler.data}
            cards={[
              {
                bottomLabel: t(
                  'reports.finance.fees.overview.number-of-single-fees-applied',
                ),
                value: feesReportOverviewHandler?.data?.transactions_quantity,
              },
              {
                bottomLabel: t('reports.finance.fees.overview.total-vat'),
                isCurrency: true,
                value: feesReportOverviewHandler?.data?.total_VAT_amount,
              },
              {
                bottomLabel: t(
                  'reports.finance.fees.overview.total-fees-vat-inclu',
                ),
                isCurrency: true,
                value: feesReportOverviewHandler?.data?.total_amount_with_VAT,
              },
            ]}
          />
        )}

      {!!feesReportHandler.data?.data?.length &&
        feesReportOverviewHandler.success &&
        !hideResult && (
          <div className={'flex justify-between my-3 h-10 items-center'}>
            <button
              className={
                'px-4 py-2 rounded focus:outline-none bg-gfCoral flex flex-row justify-center items-center font-MulishBold'
              }
              onClick={getExcelReport}
            >
              {t('export-excel')}
            </button>
            {feesReportHandler.data?.meta?.total >
              window?.appConfig?.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
              <div>
                <ReactPaginate
                  ref={pageRef}
                  previousLabel={'prev'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={
                    feesReportHandler.data?.meta?.total /
                    window?.appConfig?.REACT_APP_PAGINATION_PER_PAGE_ROWS
                  }
                  marginPagesDisplayed={4}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            )}
          </div>
        )}

      {!!feesReportHandler.data?.data?.length &&
        feesReportOverviewHandler.success &&
        !hideResult && (
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Header>{t('Date')}</Table.Header>
                <Table.Header>{t('Ticket-number')}</Table.Header>
                <Table.Header>{t('global-order-ref')}</Table.Header>
                <Table.Header>{t('Token')}</Table.Header>
                <Table.Header>{t('reports.finance.fees.type')}</Table.Header>
                {selectedTag.additional_products_enabled && (
                  <Table.Header>{t('reports.finance.fees.name')}</Table.Header>
                )}
                <Table.Header>
                  {t('reports.finance.fees.fee-net-vat-exclusive')}
                </Table.Header>
                <Table.Header>{t('vat')}</Table.Header>
                <Table.Header>
                  {t('reports.finance.fees.fee-total-vat-inclusive')}
                </Table.Header>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {feesReportHandler?.data?.data?.map((fee) => (
                <Row
                  key={`${fee.transaction_number}-${randomString(4)}`}
                  fee={fee}
                />
              ))}
            </Table.Body>
          </Table>
        )}

      <AlertHandler handler={feesReportHandler} />
    </TabItemContainer>
  );
}
