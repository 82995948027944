import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import Table from '../../../../Table';
import { checkLoggedInUserPermissions } from '../../../../../utils/Helper';

function CardRow({ mvcTokenHandler, card, phoneNumber }) {
  const { tagCurrency } = useSelector((state) => state.tag);
  const { t } = useTranslation();
  const cardPhoneNumber = useMemo(() => phoneNumber, [phoneNumber]);

  const isRecipientPhoneNumberHighlighted = useMemo(
    () => `${cardPhoneNumber.trim()}` === card.card_holder.phone_number,
  );
  const isPurchaserPhoneNumberHighlighted = useMemo(
    () => `${cardPhoneNumber.trim()}` === card.card_purchaser?.phone_number,
  );

  const cleanPhoneNumber = (phoneNumber) => {
    return phoneNumber.replace(/\D/g, '');
  };

  return (
    <Table.Row className="font-MulishRegular">
      <Table.Cell>
        <Link
          onClick={mvcTokenHandler}
          className="underline"
          to={`/gift-cards/card-information?token=${card.token}&isBackToList=true&phoneNumber=${encodeURIComponent('+')}${cleanPhoneNumber(cardPhoneNumber)}`}
        >
          {card.token}
        </Link>
      </Table.Cell>
      <Table.Cell>
        <span className="ml-2 flex justify-center text-gfLightBlue font-MulishBold">
          {String(card.status).toLocaleUpperCase()}
        </span>
      </Table.Cell>
      <Table.Cell>
        <div className="flex justify-center text-gfLightBlue font-MulishBold">
          <CurrencyFormat
            decimalScale={2}
            fixedDecimalScale={true}
            value={card.balance}
            displayType={'text'}
            thousandSeparator={true}
          />
          <span className="ml-2">{` ${tagCurrency}`}</span>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex justify-center text-gfLightBlue font-MulishBold">
          <span data-testid="expiryDate">{card.expiration_date}</span>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex justify-center text-gfLightBlue font-MulishBold">
          <span data-testid="cardPurchaserName">
            {card.card_purchaser?.last_name} {card.card_purchaser?.first_name}
          </span>
        </div>
      </Table.Cell>
      <Table.Cell
        className={
          isPurchaserPhoneNumberHighlighted ? 'bg-gfCoral bg-opacity-25' : ''
        }
      >
        <div className="flex justify-center text-gfLightBlue font-MulishBold">
          <span data-testid="telPurchaserNumber">
            {card.card_purchaser?.phone_number}
          </span>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex justify-center text-gfLightBlue font-MulishBold">
          <span data-testid="cardHolderName">
            {card.card_holder.last_name} {card.card_holder.first_name}
          </span>
        </div>
      </Table.Cell>
      <Table.Cell
        className={
          isRecipientPhoneNumberHighlighted ? 'bg-gfCoral bg-opacity-25' : ''
        }
      >
        <div className="flex justify-center text-gfLightBlue font-MulishBold">
          <span data-testid="telNumber">{card.card_holder.phone_number}</span>
        </div>
      </Table.Cell>
      {checkLoggedInUserPermissions('card-to-card-transfer-new') && (
        <Table.Cell className={'py-2'}>
          {(card.status === 'loaded' || card.status === 'active') && (
            <div className="flex justify-center text-gfDarkBlue font-MulishBold">
              <Link
                onClick={mvcTokenHandler}
                className="replaceCard bg-gfCoral py-2 px-4 rounded-md"
                to={`/gift-cards/card-transfer-new?fromToken=${card.token}`}
              >
                {t('Replace')}
              </Link>
            </div>
          )}
        </Table.Cell>
      )}
    </Table.Row>
  );
}

export default function ListCardSearchByPhone({
  mvcTokenHandler,
  phoneNumber,
}) {
  const { t } = useTranslation();
  const { listCardSearchByPhone, listCardSearchByPhoneMeta } = useSelector(
    (state) => state.cardInformation,
  );

  return (
    <>
      <div className="flex flex-row justify-end items-center border-b w-full px-4 py-2 text-xl font-MulishBold  text-gfDarkBlue bg-gfPeriwinkle bg-opacity-50">{`${
        listCardSearchByPhoneMeta.from
      } - ${listCardSearchByPhoneMeta.to} ${t('of')} ${listCardSearchByPhoneMeta.total} ${t('results')}`}</div>
      <Table>
        <Table.Head>
          <tr>
            <Table.Header>
              {t('card_information.search.headers.token')}
            </Table.Header>
            <Table.Header className="text-center">
              {t('card_information.search.headers.status')}
            </Table.Header>
            <Table.Header className="text-center">{t('Balance')}</Table.Header>
            <Table.Header className="text-center">
              {t('card_information.search.headers.expiration_date')}
            </Table.Header>
            <Table.Header className="text-center">
              {t('card_information.search.headers.card_purchaser.name')}
            </Table.Header>
            <Table.Header className="text-center">
              {t('card_information.search.headers.card_purchaser.phone_number')}
            </Table.Header>
            <Table.Header className="text-center">
              {t('card_information.search.headers.card_holder_name')}
            </Table.Header>
            <Table.Header className="text-center">
              {t('card_holder.phone_number')}
            </Table.Header>
            <Table.Header className="text-center"></Table.Header>
          </tr>
        </Table.Head>

        <Table.Body>
          {listCardSearchByPhone &&
            listCardSearchByPhone.map((card, index) => (
              <CardRow
                key={index}
                mvcTokenHandler={mvcTokenHandler}
                card={card}
                phoneNumber={phoneNumber}
              />
            ))}
        </Table.Body>
      </Table>
    </>
  );
}
