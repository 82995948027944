import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import ListCardSearchByPhone from './ListCardSearchByPhone';
import FailAlert from '../../../../Alert/FailAlert';
import { RESET_APP_COUNT } from '../../../../../actions/CommonActions';
import {
  CHANGE_SEARCH_TYPE,
  getCardsListByPhoneNumber,
} from '../../../../../actions/CardInformationActions';

export default function CardSearchByPhone({
  searchCardTypesList,
  phoneNumber,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    listCardSearchByPhoneMeta,
    errorListCardSearchByPhone,
    listCardSearchByPhone,
    selectedSearchType,
  } = useSelector((state) => state.cardInformation);
  const hasResults =
    listCardSearchByPhoneMeta && listCardSearchByPhoneMeta.total > 0;
  const totalPageCount = useMemo(() => {
    return (
      listCardSearchByPhoneMeta &&
      listCardSearchByPhoneMeta.total /
        window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS
    );
  }, [listCardSearchByPhoneMeta]);

  const paginationHandlerListCards = (event) => {
    dispatch(getCardsListByPhoneNumber(phoneNumber, event.selected + 1));
  };

  const mvcTokenHandler = () => {
    dispatch({ type: RESET_APP_COUNT });
    dispatch({ type: CHANGE_SEARCH_TYPE, payload: searchCardTypesList[0] });
  };

  return (
    <>
      <div className="float-right">
        {hasResults &&
          listCardSearchByPhoneMeta.total >
            window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS && (
            <ReactPaginate
              previousLabel={'prev'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={totalPageCount}
              marginPagesDisplayed={4}
              pageRangeDisplayed={5}
              onPageChange={paginationHandlerListCards}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
      </div>
      {hasResults && listCardSearchByPhone && (
        <ListCardSearchByPhone
          mvcTokenHandler={mvcTokenHandler}
          phoneNumber={phoneNumber}
        />
      )}

      {!hasResults &&
        selectedSearchType?.id === 1 &&
        listCardSearchByPhoneMeta && (
          <div className="mt-10">
            <FailAlert
              message={t('card_information.search.results.empty_phone_number')}
            />
          </div>
        )}
      {phoneNumber &&
        selectedSearchType?.id === 1 &&
        errorListCardSearchByPhone && (
          <div className="mt-10">
            <FailAlert
              message={t(
                'card_information.search.results.enter_valid_phone_number',
              )}
            />
          </div>
        )}
    </>
  );
}
