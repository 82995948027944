import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { format, addYears, differenceInYears } from 'date-fns';
import { Link } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import Table from '../../../Table';
import { RESET_CARD_USAGE_MERCHANT } from '../../../../actions/CardUsageAtMerchantReportActions';
import MessageAlert from '../../../Alert/MessageAlert';
import FailAlert from '../../../Alert/FailAlert';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';
import { RESET_EXCEL_REPORT } from '../../../../actions/CommonActions';
import CardInfoWidget from '../../../CardInfoWidget';
import TabItemContainer from '../../../UIComponents/TabItemContainer';
import SelectField from '../../../Input/SelectField';
import { useCardUsage } from '../../../../hooks/useCardUsage';
import useAppConfig from '../../../../hooks/useAppConfig';
import { Pagination } from '../../../Pagination';
import { Button } from '../../../Button';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';
import { FormControl, FormField, FormItem, FormLabel } from '../../../Form';
import DatePickerField from '../../../Input/DatePickerField';
import { useTags } from '../../../../hooks/useTags';
import { TaggingListDropdown } from '../../../Tags';
import {
  useTransactionTypeLabel,
  useTransactionWalletLabel,
} from '../../../../hooks/useCardInformation';

const CardTokenDetails = ({ token }) => {
  const { t } = useTranslation();

  const canAccessCardInformation = useMemo(
    () => checkLoggedInUserPermissions('card-information'),
    [checkLoggedInUserPermissions],
  );

  return (
    <div className="flex flex-col justify-center items-center">
      {canAccessCardInformation ? (
        <Link
          className="underline"
          to={`/gift-cards/card-information?token=${token}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {token}
        </Link>
      ) : (
        <div key={token}>{t(token)}</div>
      )}
    </div>
  );
};

const TransactionDetailRow = ({ transaction }) => {
  const { t } = useTranslation();

  const typeLabel = useTransactionTypeLabel(transaction.transaction_type);
  const walletLabel = useTransactionWalletLabel(transaction.wallet);

  return (
    <Table.Row id={`${transaction.transaction_id}ID`}>
      <Table.Cell id={'transactionDateID'}>{transaction.date}</Table.Cell>
      <Table.Cell id={'transactionShopNameID'}>
        {transaction.merchant_name || transaction.shop_name}
      </Table.Cell>
      <Table.Cell id={'transactionID'}>{transaction.transaction_id}</Table.Cell>
      <Table.Cell id={'transactionTokenID'}>
        <CardTokenDetails token={transaction.token} />
      </Table.Cell>
      <Table.Cell id={'transactionAmountID'}>
        <div
          className={`flex justify-center font-MulishBold items-center ${transaction.amount === 0 ? 'text-gfLightBlue' : transaction.amount > 0 ? 'text-gfPositiveAmount' : 'text-gfNegativeAmount'}`}
        >
          <ShoppingCenterMoney value={transaction.amount} />
        </div>
      </Table.Cell>
      <Table.Cell id={'transactionTypeID'}>
        {t(`card-usage-at-merchants.types.${transaction.type}`)}
      </Table.Cell>
      <Table.Cell id={'transactionPurchaseTypeID'}>
        <span>{typeLabel}</span> <br />
        {transaction.wallet && (
          <span className="text-gray-500 text-sm">{walletLabel}</span>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

const formatDate = (date) => (date ? format(date, 'yyyy-MM-dd') : null);

function Filters({ onSubmit, isLoading, merchants, categories }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, setValue, watch } = useFormContext();
  const { fromDate } = watch(['fromDate']);
  const { selectedTag } = useSelector((state) => state.tag);

  const maxDate = useMemo(() => {
    if (fromDate === '') {
      return new Date();
    }

    const years = differenceInYears(new Date(), fromDate);

    if (years !== 0) {
      return addYears(fromDate, 1);
    }

    return new Date();
  }, [fromDate]);

  const merchantOptions = useMemo(
    () => [
      { label: t('All'), value: '' },
      ...merchants.map((merchant) => ({
        label: merchant.name,
        value: merchant.id,
      })),
    ],
    [merchants, t],
  );

  const categoryOptions = useMemo(
    () => [
      { label: t('All'), value: '' },
      ...categories.map((merchant) => ({
        label: merchant.name,
        value: merchant.code,
      })),
    ],
    [categories, t],
  );

  function onResetResults() {
    dispatch({ type: RESET_CARD_USAGE_MERCHANT });
    dispatch({ type: RESET_EXCEL_REPORT });
  }

  return (
    <form
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-5 items-end mb-10"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormField
        name="fromDate"
        render={(field) => (
          <FormItem>
            <FormControl>
              <DatePickerField
                dataTestId={'chooseDateID'}
                disabled={isLoading}
                label={t('Choose-the-date')}
                dateFormat="dd/MM/yyyy"
                selected={field.value}
                maxDate={new Date()}
                onChange={(date) => {
                  field.onChange(date);
                  onResetResults();
                  setValue('toDate', null);
                }}
              />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        name="toDate"
        render={(field) => {
          return (
            <FormItem>
              <FormControl>
                <DatePickerField
                  dataTestId={'endDateID'}
                  label={`${t('End-date')} (${t('Optional')})`}
                  dateFormat="dd/MM/yyyy"
                  selected={field.value}
                  disabled={isLoading || fromDate === ''}
                  minDate={fromDate}
                  maxDate={maxDate}
                  onChange={(date) => {
                    field.onChange(date);
                    onResetResults();
                  }}
                />
              </FormControl>
            </FormItem>
          );
        }}
      />
      <FormField
        name="merchantId"
        render={(field) => {
          const selected = merchantOptions.find(
            (option) => option.value === field.value,
          );

          return (
            <FormItem data-test-id="merchantListDropdownID">
              <FormLabel>
                {t('card-usage-at-merchants.select-merchant')}:
              </FormLabel>
              <FormControl>
                <SelectField
                  options={merchantOptions}
                  onChange={(event) => {
                    field.onChange(event.value);
                    onResetResults();
                  }}
                  value={selected}
                />
              </FormControl>
            </FormItem>
          );
        }}
      />
      <FormField
        name="merchantCode"
        render={(field) => {
          const selected = categoryOptions.find(
            (option) => option.value === field.value,
          );

          return (
            <FormItem>
              <FormLabel>
                {t('card-usage-at-merchants.select-merchant-category')}:
              </FormLabel>
              <FormControl>
                <SelectField
                  options={categoryOptions}
                  onChange={(event) => {
                    field.onChange(event.value);
                    onResetResults();
                  }}
                  value={selected}
                />
              </FormControl>
            </FormItem>
          );
        }}
      />
      {selectedTag.enabled_tagging && (
        <FormField
          name="tag"
          render={(field) => {
            return (
              <FormItem>
                <FormLabel>{t('reports.filter.select-tag')}:</FormLabel>
                <FormControl>
                  <TaggingListDropdown
                    onChangeHandler={(event) => {
                      field.onChange(event);
                      onResetResults();
                    }}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            );
          }}
        />
      )}
      <Button
        variant="primary"
        type="submit"
        disabled={isLoading || !fromDate}
        loading={isLoading}
      >
        {t('Go')}!
      </Button>
    </form>
  );
}

const defaultValues = {
  fromDate: '',
  toDate: '',
  merchantId: '',
  merchantCode: '',
  tagging_id: '',
};

export default function CardUsageAtMerchantReport() {
  const { t } = useTranslation();
  const { getConfigValue } = useAppConfig();
  const perPage = getConfigValue('REACT_APP_PAGINATION_PER_PAGE_ROWS');
  const dispatch = useDispatch();
  const formMethods = useForm({
    defaultValues,
  });

  const {
    merchants,
    fetchSummary,
    fetchTransactions,
    exportTransactions,
    categories,
  } = useCardUsage();
  const {
    loadingCardUsageMerchant,
    receivedCardUsageMerchant,
    errorCardUsageMerchant,
    metaCardUsageMerchant,
    receivedCardUsageMerchantSummary,
    errorCardUsageMerchantSummary,
  } = useSelector((state) => state.cardUsageAtMerchant);
  const { selectedTag } = useSelector((state) => state.tag);

  const { appCount, loadingExcel, errorExcel } = useSelector(
    (state) => state.commonReducer,
  );

  useEffect(() => {
    Userpilot.reload();
    if (appCount === 0) {
      dispatch({ type: RESET_CARD_USAGE_MERCHANT });
    }
  }, [appCount, dispatch]);

  function onFetchReport(data) {
    dispatch({ type: RESET_EXCEL_REPORT });

    const from = formatDate(data.fromDate);
    const to = formatDate(data.toDate) || from;

    const payload = {
      from,
      to,
      merchantId: data.merchantId,
      merchantCode: data.merchantCode,
    };

    if (selectedTag.enabled_tagging && data?.tag) {
      payload.tagging_id = data.tag.value;
    }

    fetchTransactions({
      ...payload,
      page: 1,
      perPage,
    });
    fetchSummary(payload);
  }

  function onExport() {
    const data = formMethods.getValues();
    const from = formatDate(data.fromDate);
    const to = formatDate(data.toDate) || from;

    const payload = {
      from,
      to,
      merchantId: data.merchantId,
      merchantCode: data.merchantCode,
      reportName: 'card_usage_at',
    };

    if (selectedTag.enabled_tagging && data?.tag) {
      payload.tagging_id = data.tag.value;
      payload.reportName = `${data.tag.label}_${payload.reportName}`;
    }
    exportTransactions(payload);
  }

  function onPaginate({ selected }) {
    const data = formMethods.getValues();
    const from = formatDate(data.fromDate);
    const to = formatDate(data.toDate) || from;

    const payload = {
      from,
      to,
      merchantId: data.merchantId,
      merchantCode: data.merchantCode,
      page: selected + 1,
      perPage,
    };

    if (selectedTag.enabled_tagging && data?.tag) {
      payload.tagging_id = data.tag.value;
    }
    fetchTransactions(payload);
  }

  return (
    <TabItemContainer
      title={t('reports.usage.usage-report.title')}
      description={
        <span className="whitespace-pre-line">
          {t('card-usage-at-merchants.performance-message')}
        </span>
      }
    >
      <FormProvider {...formMethods}>
        <div className="space-y-4">
          <Filters
            onSubmit={onFetchReport}
            isLoading={loadingCardUsageMerchant}
            merchants={merchants}
            categories={categories}
          />
          {receivedCardUsageMerchant &&
            receivedCardUsageMerchant.length === 0 && (
              <div className="my-10">
                <MessageAlert
                  message={t(
                    'There-are-no-information-to-display-for-this-date',
                  )}
                />
              </div>
            )}

          {errorCardUsageMerchant && (
            <div className="my-10">
              <FailAlert message={errorCardUsageMerchant.message} />
            </div>
          )}

          {errorCardUsageMerchantSummary && (
            <div className="my-10">
              <FailAlert message={errorCardUsageMerchantSummary.message} />
            </div>
          )}

          {errorExcel && (
            <div className="my-10">
              <FailAlert message={errorExcel.message} />
            </div>
          )}

          {receivedCardUsageMerchant &&
            receivedCardUsageMerchant.length !== 0 &&
            receivedCardUsageMerchantSummary && (
              <div className="flex flex-col space-y-5">
                <div className="grid grid-cols-1  md:grid-cols-5 gap-5 md:gap-5">
                  <CardInfoWidget
                    id={'netTotalAmountOwnedToMerchantID'}
                    backgroundColor="bg-gfCoral"
                    bottomLabel={t(
                      'card-usage-at-merchants.net-total-amount-owned-to-merchants',
                    )}
                    isCurrency
                    value={receivedCardUsageMerchantSummary.total_amount}
                    labelColor={'text-gfDarkBlue'}
                    valueColor={'text-gfDarkBlue'}
                  />
                  <CardInfoWidget
                    id={'totalAmountOfSpendsID'}
                    bottomLabel={t(
                      'card-usage-at-merchants.total-amount-of-spends',
                    )}
                    isCurrency
                    value={receivedCardUsageMerchantSummary.total_spends}
                    labelColor={'text-gfDarkBlue'}
                    valueColor={'text-gfDarkBlue'}
                  />
                  <CardInfoWidget
                    id={'totalAmountOfRefundCancellationsID'}
                    bottomLabel={t(
                      'card-usage-at-merchants.total-amount-of-refund-cancellations',
                    )}
                    isCurrency
                    value={receivedCardUsageMerchantSummary.total_refunds}
                    labelColor={'text-gfLightBlue'}
                    valueColor={'text-gfLightBlue'}
                  />
                  <CardInfoWidget
                    id={'numberOfSingleTransactionsID'}
                    bottomLabel={t(
                      'card-usage-at-merchants.number-of-single-transactions',
                    )}
                    isCurrency={false}
                    value={receivedCardUsageMerchantSummary.transaction_count}
                    labelColor={'text-gfDarkBlue'}
                    valueColor={'text-gfDarkBlue'}
                  />
                  <CardInfoWidget
                    id={'numberOfGiftCardsUsedID'}
                    bottomLabel={t(
                      'card-usage-at-merchants.number-of-gift-cards-used',
                    )}
                    isCurrency={false}
                    value={receivedCardUsageMerchantSummary.gift_cards_count}
                    labelColor={'text-gfLightBlue'}
                    valueColor={'text-gfLightBlue'}
                  />
                </div>
                <div className="flex flex-col items-end my-4">
                  <div className="flex flex-row mb-5">
                    <Button
                      id="exportCsv"
                      variant="primary"
                      onClick={onExport}
                      loading={loadingExcel}
                    >
                      {t('export-excel')}
                    </Button>
                  </div>

                  {receivedCardUsageMerchant &&
                    metaCardUsageMerchant &&
                    metaCardUsageMerchant.total >
                      metaCardUsageMerchant.per_page && (
                      <div>
                        <Pagination
                          pageCount={
                            metaCardUsageMerchant.total /
                            metaCardUsageMerchant.per_page
                          }
                          onPageChange={onPaginate}
                        />
                      </div>
                    )}
                </div>
                <Table className="rounded border">
                  <Table.Head>
                    <tr>
                      <Table.Header id="dateID">
                        {t('card-usage-at-merchants.date')}
                      </Table.Header>
                      <Table.Header
                        id="shopNameID"
                        scope="col"
                        className="px-2 py-3  text-center  uppercase tracking-wider"
                      >
                        {t('card-usage-at-merchants.shop-name')}
                      </Table.Header>
                      <Table.Header id="transactionID">
                        {t('card-usage-at-merchants.transaction-id')}
                      </Table.Header>
                      <Table.Header id="cardTokenID">
                        {t('card-usage-at-merchants.card-token')}
                      </Table.Header>
                      <Table.Header id="transactionAmountID">
                        {t('card-usage-at-merchants.transaction-amount')}
                      </Table.Header>
                      <Table.Header id="transactionTypeID">
                        {t('card-usage-at-merchants.transaction-type')}
                      </Table.Header>
                      <Table.Header id="transactionPurchaseTypeID">
                        {t('card-usage-at-merchants.transaction-purchase-type')}
                      </Table.Header>
                    </tr>
                  </Table.Head>
                  <Table.Body>
                    {receivedCardUsageMerchant &&
                      receivedCardUsageMerchant.map((tr) => (
                        <TransactionDetailRow
                          key={tr.transaction_id}
                          transaction={tr}
                        />
                      ))}
                  </Table.Body>
                </Table>
              </div>
            )}
        </div>
      </FormProvider>
    </TabItemContainer>
  );
}
