import React, { useEffect, useMemo, useRef, useState } from 'react';
import Tooltip from 'react-simple-tooltip';
import { useTranslation } from 'react-i18next';
import CurrencyFormat from 'react-currency-format';
import { GF_DARK_BLUE, GF_PERIWINKLE } from '../../../utils/Constants';
import EditIcon from '../../../assets/svg/EditIcon';
import TrashIcon from '../../../assets/svg/TrashIcon';
import Table from '../../Table';
import Container from '../../UIComponents/Container';
import EditOrCreateTagDialog from './EditOrCreateTagDialog';
import TagDeleteConfirmation from './TagDeleteConfirmation';
import taggingAPI from '../../../api/taggingAPI';
import useApiHook from '../../../hooks/useAPI';
import { Button } from '../../Button';
import { Pagination } from '../../Pagination';
import useAppConfig from '../../../hooks/useAppConfig';
import LockIcon from '../../../assets/svg/LockIcon';
import SuccessAlert from '../../Alert/SuccessAlert';

function Tag({ tag, onEdit, onDelete }) {
  const { t } = useTranslation();
  return (
    <Table.Row>
      <Table.Cell>
        <div className="flex justify-center items-center">
          {tag.created_date}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex justify-center items-center">{tag.name}</div>
      </Table.Cell>
      <Table.Cell className={'flex justify-center items-center'}>
        <Tooltip
          content={t('tags.manage.tooltip.edit')}
          customCss={`
                      white-space: nowrap;
                      z-index: 9999;
                  `}
          zIndex={9999}
          background="#FFFFFF"
          border={GF_PERIWINKLE}
          color={GF_DARK_BLUE}
          fontFamily="inherit"
          radius={4}
          padding={10}
          placement="top"
        >
          <div className={'mr-2'}>
            <Button variant={'primary'} onClick={() => onEdit(tag.id)}>
              <EditIcon />
            </Button>
          </div>
        </Tooltip>
        <Tooltip
          content={
            !tag.allow_delete
              ? t('tags.manage.tooltip.delete_disabled')
              : t('tags.manage.tooltip.delete')
          }
          customCss={`
                      white-space: nowrap;
                      z-index: 9999;
                  `}
          zIndex={9999}
          background="#FFFFFF"
          border={GF_PERIWINKLE}
          color={GF_DARK_BLUE}
          fontFamily="inherit"
          radius={4}
          padding={10}
          placement="top"
        >
          {tag.allow_delete ? (
            <div>
              <Button
                variant={'secondary'}
                onClick={() => onDelete(tag.id)}
                disabled={!tag.allow_delete}
              >
                <TrashIcon />
              </Button>
            </div>
          ) : (
            <div>
              <Button
                variant={'secondary'}
                onClick={() => onDelete(tag.id)}
                disabled={!tag.allow_delete}
              >
                <LockIcon />
              </Button>
            </div>
          )}
        </Tooltip>
      </Table.Cell>
    </Table.Row>
  );
}

export default function TagManagement() {
  const { t } = useTranslation();
  const [addTagIsOpen, setAddTagIsOpen] = useState(false);
  const pageRef = useRef(null);
  const { getConfigValue } = useAppConfig();
  const perPage = getConfigValue('REACT_APP_PAGINATION_PER_PAGE_ROWS');
  const [getTagsHandler, getTags] = useApiHook(taggingAPI.getTags);
  const [editTag, setEditTag] = useState({ operation: 'edit', id: null });
  const [deletedTag, setDeletedTag] = useState(false);
  const selectedTag = useMemo(() => {
    if (editTag.id) {
      return (getTagsHandler.data?.data || []).find(
        (tag) => tag.id === editTag.id,
      );
    }
    return null;
  }, [editTag.id, editTag.operation, getTagsHandler.data]);

  const isEditing = useMemo(
    () => selectedTag && editTag.operation === 'edit',
    [editTag, selectedTag],
  );

  const isDeleting = useMemo(
    () => selectedTag && editTag.operation === 'delete',
    [editTag, selectedTag],
  );

  useEffect(() => {
    getTags({ perPage });
  }, []);

  const onSuccess = () => {
    if (editTag.operation === 'delete') {
      setDeletedTag(true);
      setTimeout(() => {
        setDeletedTag(false);
      }, 2000);
    }

    getTags({ perPage });
  };

  const columns = useMemo(
    () =>
      [
        {
          accessorKey: 'creationDate',
          header: t('tags.manage.results.creation_date'),
        },
        {
          accessorKey: 'tagName',
          header: t('tags.manage.results.tag_name'),
        },
        { accessorKey: 'actions', header: t('tags.manage.results.actions') },
      ].filter((column) => !column.hidden),
    [t],
  );

  const onCloseTagForm = () => {
    setAddTagIsOpen(false);
    setEditTag({ operation: 'edit', id: null });
  };

  const onEditTag = (id) => {
    setEditTag({ operation: 'edit', id });
  };

  const onDeleteTag = (id) => {
    setEditTag({ operation: 'delete', id });
  };

  const onCloseDeleteTag = () => {
    setEditTag({ operation: 'delete', id: null });
  };

  return (
    <Container title={t('tags.manage.title')}>
      {deletedTag && (
        <div className="w-full mb-10">
          <SuccessAlert message={t('Success')} />
        </div>
      )}
      <div className="flex justify-end w-full">
        <Tooltip
          content={t('tags.manage.button.add')}
          customCss={`white-space: nowrap;z-index: 1;`}
          zIndex={1}
          background="#FFF"
          border={GF_PERIWINKLE}
          color={GF_DARK_BLUE}
          fontFamily="inherit"
          radius={4}
          padding={10}
          placement="top"
        >
          <Button
            onClick={() => {
              setAddTagIsOpen(true);
            }}
            variant={'primary'}
          >
            {t('tags.manage.button.add')}
          </Button>
        </Tooltip>
      </div>
      <div className={'flex justify-end my-3'}>
        {getTagsHandler.data?.meta?.total > perPage && (
          <div>
            <Pagination
              pageCount={getTagsHandler.data?.meta?.total / perPage}
              pageRef={pageRef}
              onPageChange={(selectedPage) => {
                getTags({ perPage, page: selectedPage.selected + 1 });
              }}
            />
          </div>
        )}
      </div>
      <div className="flex flex-col space-y-5">
        {!!getTagsHandler.data?.data?.length && (
          <>
            <div className="flex flex-row justify-end items-center border-b w-full px-4 py-2 text-xl font-MulishBold  text-gfDarkBlue bg-gfPeriwinkle bg-opacity-50">{`${
              getTagsHandler.data?.meta?.from
            } - ${getTagsHandler.data?.meta?.to} ${t('of')} ${getTagsHandler.data?.meta?.total} ${t('results')}`}</div>
            <Table>
              <Table.Head>
                <tr>
                  {columns.map((column) => {
                    return (
                      <Table.Header key={column.accessorKey}>
                        {column.header}
                      </Table.Header>
                    );
                  })}
                </tr>
              </Table.Head>
              <Table.Body>
                {getTagsHandler?.data?.data?.map((tag) => (
                  <Tag
                    tag={tag}
                    onEdit={onEditTag}
                    onDelete={onDeleteTag}
                    key={tag.id}
                  />
                ))}
              </Table.Body>
            </Table>
          </>
        )}
      </div>
      {(isEditing || addTagIsOpen) && (
        <EditOrCreateTagDialog
          onClose={onCloseTagForm}
          onSuccess={onSuccess}
          tagData={isEditing ? selectedTag : null}
        />
      )}
      {isDeleting && (
        <TagDeleteConfirmation
          onClose={onCloseDeleteTag}
          tagData={selectedTag}
          onSuccess={onSuccess}
        />
      )}
    </Container>
  );
}
