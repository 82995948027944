/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Userpilot } from 'userpilot';
import ButtonLoading from '../../../../assets/svg/ButtonLoading';
import PageTitle from '../../../PageTitle';
import {
  getFromMVCInfo,
  getToMVCInfo,
  MVCToMVCTransferAndTerminate,
  MVC_TRANSFER_OPERATION_ID,
  RESET_ALL_MVC_MVC_TRANSFER,
  RESET_MVC_MVC_TRANSFER,
} from '../../../../actions/MVCToMVCTransferActions';
import { issuingAPI } from '../../../../services/Axios';
import LoadingIndicator from '../../../Dropdown/Components/LoadingIndicator';
import FailAlert from '../../../Alert/FailAlert';
import SuccessAlert from '../../../Alert/SuccessAlert';
import { TAG_CHANGED } from '../../../../actions/TagActions';
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions';
import RightArrowIcon from '../../../../assets/svg/RightArrowIcon';
import Container from '../../../UIComponents/Container';
import {
  GF_CORAL,
  GF_DARK_BLUE,
  GF_GREY,
  GF_PERIWINKLE,
} from '../../../../utils/Constants';

export default function MVCToMVCTransfer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedFromMVC, setSelectedFromMVC] = useState(null);
  const [selectedToMVC, setSelectedToMVC] = useState(null);
  const [identicalValidator, setIdenticalValidator] = useState(false);
  const [balanceValidator, setBalanceValidator] = useState(false);
  const [balanceInsufficient, setBalanceInsufficient] = useState(false);
  const [debitPage, setDebitPage] = useState(1);
  const [creditPage, setCreditPage] = useState(1);
  const [amount, setAmount] = useState('');

  useEffect(() => {
    Userpilot.reload();
    dispatch({ type: RESET_ALL_MVC_MVC_TRANSFER });

    dispatch({ type: MVC_TRANSFER_OPERATION_ID });

    window.addEventListener(TAG_CHANGED, () => {
      dispatch({ type: RESET_ALL_MVC_MVC_TRANSFER });
    });

    window.addEventListener(TERMINAL_CHANGED, () => {
      dispatch({ type: RESET_ALL_MVC_MVC_TRANSFER });
    });

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {});
      window.removeEventListener(TERMINAL_CHANGED, () => {});
    };
  }, []);

  const {
    loadingFromMVC,
    receivedFromMVC,
    errorFromMVC,

    loadingToMVC,
    receivedToMVC,
    errorToMVC,

    transferringMVC,
    successTransfer,
    failTransfer,
  } = useSelector((state) => state.mvc_mcvTransfer);
  const { tagCurrency, tagID } = useSelector((state) => state.tag);

  const fromTokenChangeHandler = (event) => {
    setIdenticalValidator(false);
    setBalanceValidator(false);
    if (successTransfer || failTransfer) {
      dispatch({ type: RESET_MVC_MVC_TRANSFER });
    }
    setSelectedFromMVC(event);
    dispatch(getFromMVCInfo(event.mvc_token));
  };

  const toTokenChangeHandler = (event) => {
    setIdenticalValidator(false);
    setBalanceValidator(false);
    if (successTransfer || failTransfer) {
      dispatch({ type: RESET_MVC_MVC_TRANSFER });
    }
    setSelectedToMVC(event);
    dispatch(getToMVCInfo(event.mvc_token));
  };

  async function getDebitTokens() {
    const params = {
      non_transhandler: true,
    };
    const { data } = await issuingAPI.get(`tag/${tagID}/mvc-payment-method`, {
      params,
    });
    setDebitPage((pp) => pp + 1);
    return {
      options: data.data,
    };
  }

  async function getCreditTokens() {
    const params = {
      non_transhandler: true,
    };
    const { data } = await issuingAPI.get(`tag/${tagID}/mvc-payment-method`, {
      params,
    });
    setCreditPage((pp) => pp + 1);
    return {
      options: data.data,
    };
  }

  const transferHandler = () => {
    if (receivedFromMVC !== null && receivedFromMVC.balance_available !== 0) {
      setBalanceValidator(false);
      if (amount <= receivedFromMVC.balance_available) {
        setBalanceInsufficient(false);
        if (receivedFromMVC.token !== receivedToMVC.token) {
          setIdenticalValidator(false);
          dispatch(
            MVCToMVCTransferAndTerminate(
              receivedFromMVC.token,
              receivedToMVC.token,
              amount,
            ),
          );
        } else {
          setIdenticalValidator(true);
        }
      } else {
        setBalanceInsufficient(true);
      }
    } else {
      setBalanceValidator(true);
    }
  };

  const customStyles = (value) => ({
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && state.data.mvc_token === value.mvc_token
          ? GF_DARK_BLUE
          : 'white',
      padding: 12,
      fontSize: '18px',
      color:
        state.isSelected && state.data.mvc_token === value.mvc_token
          ? '#FFF'
          : GF_DARK_BLUE,
      '&:hover': {
        background: GF_CORAL,
        color: '#FFF',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: GF_GREY,
      fontSize: '18px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
      fontSize: '18px',
    }),
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: GF_PERIWINKLE,
      borderRadius: '4px',
      minHeight: '40px',
      color: '#4A5568',
      cursor: 'pointer',

      '&:hover': {
        borderColor: GF_PERIWINKLE,
        color: '#4A5568',
      },

      '&:focus': {
        borderColor: GF_PERIWINKLE,
        background: GF_PERIWINKLE,
      },
    }),
  });

  return (
    <Container title={t('mvc-to-mvc-transfer')}>
      <div>
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 lg:grid-flow-col xl:grid-flow-col 2xl:grid-flow-col  gap-5">
            <div
              className="flex flex-col w-full lg:w-72 md:w-72"
              data-testid="mvcToDebit"
            >
              <AsyncPaginate
                className="basic-single"
                classNamePrefix="select"
                styles={customStyles(selectedFromMVC)}
                placeholder={t('transfers.mvc-to-mvc.from')}
                components={{
                  LoadingIndicator,
                }}
                getOptionLabel={(option) =>
                  `${option.mvc_token} - ${option.name}`
                }
                loadOptions={getDebitTokens}
                value={selectedFromMVC}
                onChange={fromTokenChangeHandler}
                isSearchable={false}
                additional={{
                  page: debitPage,
                }}
              />

              {(loadingFromMVC || receivedFromMVC || errorFromMVC) && (
                <div
                  className="flex flex-col py-2 px-4 border rounded shadow mt-5"
                  data-testid="mvcToDebitInfo"
                >
                  <div>
                    {receivedFromMVC && (
                      <div className="space-y-3">
                        <div className="flex justify-center items-center text-sm uppercase font-semibold">
                          {t('transfers.mvc-to-mvc.from')}
                        </div>
                        <div className="" role="term" data-testid="balance">
                          {t('Available-Balance')}:{' '}
                          <span
                            className=" text-gfLightBlue font-MulishBlack"
                            role="definition"
                          >
                            <CurrencyFormat
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={receivedFromMVC.balance_available}
                              displayType={'text'}
                              thousandSeparator={true}
                            />{' '}
                            {`${String(tagCurrency).toUpperCase()}`}
                          </span>
                        </div>
                        {/* <div>
                                                    {t("Expiry-date")}:{" "}
                                                    <span className="">{receivedFromMVC.expiration_date}</span>
                                                </div> */}
                        {receivedFromMVC.status === 'terminate' && (
                          <div role="term" data-testid="status">
                            {t('Status')}:{' '}
                            <span
                              className="bg-gfGrey font-MulishBold"
                              role="definition"
                            >
                              {String(
                                receivedFromMVC.status,
                              ).toLocaleUpperCase()}
                            </span>
                          </div>
                        )}
                        {receivedFromMVC.status === 'active' && (
                          <div role="term" data-testid="status">
                            {t('Status')}:{' '}
                            <span
                              className="text-gfLightBlue font-MulishBold"
                              role="definition"
                            >
                              {String(
                                receivedFromMVC.status,
                              ).toLocaleUpperCase()}
                            </span>
                          </div>
                        )}
                        {receivedFromMVC.status === 'inactive' && (
                          <div role="term" data-testid="status">
                            {t('Status')}:{' '}
                            <span
                              className="text-gfPeriwinkle font-MulishBold"
                              role="definition"
                            >
                              {String(
                                receivedFromMVC.status,
                              ).toLocaleUpperCase()}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    <div>
                      {errorFromMVC && (
                        <FailAlert message={errorFromMVC.message} />
                      )}
                    </div>
                  </div>

                  {loadingFromMVC && (
                    <div className="flex flex-row justify-center items-center mx-auto">
                      <ButtonLoading color={'#4A5568'} />
                      {loadingFromMVC && (
                        <span>{t('Getting-card-information')}...</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="flex justify-center items-center   w-full sm:w-full md:w-full lg:w-10 rotate-90 sm:rotate-90 lg:rotate-0  transform  md:rotate-90">
              <RightArrowIcon color={GF_CORAL} />
            </div>

            <div
              className="flex flex-col w-full lg:w-72 md:w-72"
              data-testid="mvcToCredit"
            >
              <AsyncPaginate
                styles={customStyles(selectedToMVC)}
                isDisabled={receivedFromMVC === null}
                placeholder={t('transfers.mvc-to-mvc.to')}
                components={{
                  LoadingIndicator,
                }}
                getOptionLabel={(option) =>
                  `${option.mvc_token} - ${option.name}`
                }
                loadOptions={getCreditTokens}
                value={selectedToMVC}
                onChange={toTokenChangeHandler}
                isSearchable={false}
                additional={{
                  page: setCreditPage,
                }}
              />

              {(loadingToMVC || receivedToMVC || errorToMVC) && (
                <div
                  className="flex flex-col py-2 px-4 border rounded shadow mt-5"
                  data-testid="mvcToCreditInfo"
                >
                  <div>
                    {receivedToMVC && (
                      <div className="space-y-3">
                        <div className="flex justify-center items-center text-sm uppercase font-semibold">
                          {t('transfers.mvc-to-mvc.to')}
                        </div>
                        <div className="" role="term" data-testid="balance">
                          {t('Available-Balance')}:{' '}
                          <span
                            className=" text-gfLightBlue font-MulishBlack"
                            role="definition"
                          >
                            <CurrencyFormat
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={receivedToMVC.balance_available}
                              displayType={'text'}
                              thousandSeparator={true}
                            />{' '}
                            {`${String(tagCurrency).toUpperCase()}`}
                          </span>
                        </div>
                        {/* <div>
                                                    {t("Expiry-date")}:{" "}
                                                    <span className="">{receivedToMVC.expiration_date}</span>
                                                </div> */}
                        {receivedToMVC.status === 'terminate' && (
                          <div role="term" data-testid="status">
                            {t('Status')}:{' '}
                            <span
                              className="bg-gfGrey font-MulishBold"
                              role="definition"
                            >
                              {String(receivedToMVC.status).toLocaleUpperCase()}
                            </span>
                          </div>
                        )}
                        {receivedToMVC.status === 'active' && (
                          <div role="term" data-testid="status">
                            {t('Status')}:{' '}
                            <span
                              className="text-gfLightBlue font-MulishBold"
                              role="definition"
                            >
                              {String(receivedToMVC.status).toLocaleUpperCase()}
                            </span>
                          </div>
                        )}
                        {receivedToMVC.status === 'inactive' && (
                          <div role="term" data-testid="status">
                            {t('Status')}:{' '}
                            <span
                              className="text-gfPeriwinkle font-MulishBold"
                              role="definition"
                            >
                              {String(receivedToMVC.status).toLocaleUpperCase()}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                    <div>
                      {errorToMVC && <FailAlert message={errorToMVC.message} />}
                    </div>
                  </div>

                  {loadingToMVC && (
                    <div className="flex flex-row justify-center items-center mx-auto">
                      <ButtonLoading color={'#4A5568'} />
                      <span>{t('Getting-card-information')}...</span>
                    </div>
                  )}
                </div>
              )}
            </div>

            <input
              data-testid="amountInput"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              disabled={receivedFromMVC === null || receivedToMVC === null}
              type="number"
              className={` ${
                receivedFromMVC === null || receivedToMVC === null
                  ? 'bg-giftifyWhiteSmoke'
                  : 'bg-white'
              } h-10 border w-full lg:w-48 xl:w-full 2xl:w-full rounded focus:outline-none placeholder-kadozMediumGray focus:border-gfPeriwinkle text-lg px-4`}
              placeholder={t('Amount-in-EUR', { currency: tagCurrency })}
              onWheel={(e) => e.target.blur()}
            />

            <button
              data-testid="transferButton"
              disabled={
                receivedFromMVC === null ||
                receivedToMVC === null ||
                amount === ''
              }
              onClick={transferHandler}
              className={`${
                receivedFromMVC === null ||
                receivedToMVC === null ||
                amount === ''
                  ? 'bg-opacity-50'
                  : 'bg-opacity-100'
              } bg-gfCoral w-full lg:w-full xl:w-full 2xl:w-full  rounded text-gfDarkBlue flex flex-row justify-center items-center font-MulishBold py-2 px-3 h-10 focus:outline-none`}
            >
              {transferringMVC && <ButtonLoading color={'#FFFFFF'} />}
              {t('Transfer')}
            </button>
          </div>
        </div>

        <div className="py-8 px-16" data-testid="alerts">
          {failTransfer && <FailAlert message={failTransfer.message} />}
          {successTransfer && (
            <SuccessAlert
              title={t('Done')}
              message={t('Successfully-Transferred')}
            />
          )}
          {identicalValidator && (
            <FailAlert
              message={'Selected Debit token and Credit token are identical'}
            />
          )}
          {balanceValidator && (
            <FailAlert message={`Debit card balance is 0.00 ${tagCurrency}`} />
          )}
          {balanceInsufficient && (
            <FailAlert
              message={'Amount is greater than available balance of debit card'}
            />
          )}
        </div>
      </div>
    </Container>
  );
}
